import { apiInstance } from "./ApiInstance";

class PersonaDocumentoService {
    
    static registrar(data){
        return apiInstance.post(`/persona-documento`, data);
    }

    static async actualizar(data, id){
        try {
            const response = await apiInstance.patch(`/persona-documento/${id}`, data);
            return response;
        } catch (error) {
            return error;
        }
    }
    
    static async documentosPersona(personaId){
        try {
            const response = await apiInstance.get(`/persona-documento/persona/${personaId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    static async verificarRequisitos(data, id){
        try {
            const response = await apiInstance.patch(`/persona-documento/verificar-requisitos/${id}`, data);
            return response;
        } catch (error) {
            return error;
        }
    }
}

export { PersonaDocumentoService }