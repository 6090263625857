const dataURLtoFile = (dataurl, filename) => {
  try {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  } catch (e) {
    return ''
  }
}

function base64ToBlob(base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return new Blob([bytes], { type: 'application/pdf' })
}

function base64ToArrayBuffer(base64) {
  // var binary_string = window.atob(base64);
  // var len = binary_string.length;
  // var bytes = new Uint8Array(len);
  // for (var i = 0; i < len; i++) {
  //   bytes[i] = binary_string.charCodeAt(i);
  // }
  // return bytes.buffer;

  const parts = base64.split(',');
  const byteCharacters = atob(parts.length == 2 ? parts[1] : base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray.buffer;

}

function base64ToObjectURL(base64,tipoDocumento) {
  const parts = base64.split(',');
  const byteCharacters = atob(parts.length == 2 ? parts[1] : base64);
  const buf = new ArrayBuffer(byteCharacters.length);
  const byteNumbers = new Uint8Array(buf);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteNumbers], { 'type': tipoDocumento });
  const objectURL = URL.createObjectURL(blob)
  return objectURL
}

function binToBase64(pdfData) {
  const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const base64Data = reader.result.split(',')[1];
      resolve(base64Data);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(pdfBlob);
  });
}

export { dataURLtoFile, base64ToBlob, base64ToArrayBuffer, binToBase64,base64ToObjectURL }
