import { apiInstance } from "./ApiInstance";

class ArchivoService{
    static lista(filtro){
        return apiInstance.get(`/archivo?filtro=${filtro}`);
    }

    static getArchivo(id){
        return apiInstance.get(`/archivo/${id}`);
    }

    static getArchivoBase64(id){
        return apiInstance.get(`/archivo/base64/${id}`);
    }

    static registrarBase64(archivo){
        return apiInstance.post(`/archivo/base64`, archivo);
    }

    static async eliminar(id){
        try {
            const response = await apiInstance.delete(`/archivo/${id}`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}

export { ArchivoService }