<template>
    <div>
        <Dialog id="modalVisor" header="Visor de Documento" :visible="visibleModal" :position="position" :breakpoints="{'960px': '98vw'}" :style="{width: '80vw'}" :modal="true">
            <template #header>
                <div class="flex align-items-center w-full">
                    <h5 v-if="!isMobile">Visor de Documento</h5>
                    <Button v-if="isPdf && isMobile" label="Descargar" :id="idConfig.download" icon="pi pi-download" class="p-button-primary" />
                    
                    <Button @click="cerrarModal" icon="pi pi-times" class="p-button-rounded p-button-outlined ml-auto"/>
                </div>
            </template>
            <div v-if="dataUrl !== null" class="visor-pdf">
                <div v-if="isMobile">
                    <vue-pdf-app                                              
                        v-if="pdf !== null && pdf !== undefined && isPdf"
                        class="pdf-viewer"
                        :id-config="idConfig"
                        page-scale="page-width"
                        :config="config"
                        :pdf="pdf"
                        file-name="documento.pdf"
                    >
                    </vue-pdf-app>
                    <img v-else :src="dataUrl" style="width: 100%;">
                </div>                               
                <template v-else>
                    <!-- 'data:application/pdf;base64,' +  -->
                    <iframe
                        v-if="hasHeader===false"
                        class="pdf-viewer"
                        :src="'data:application/pdf;base64,' + dataUrl"
                        frameborder="0"
                        height="500"
                        width="100%"
                    ></iframe>
                    <iframe
                        v-if="hasHeader"
                        class="pdf-viewer"
                        :src="dataUrl"
                        frameborder="0"
                        height="500"
                        width="100%"
                    ></iframe>
                </template>
            </div>
            <template #footer>
                <Button label="Cerrar" @click="cerrarModal" icon="pi pi-times" class="p-button-primary"/>
            </template>
        </Dialog>
    </div>
</template>
<script>
    import VuePdfApp from "vue3-pdf-app";
    import "vue3-pdf-app/dist/icons/main.css";
    import { base64ToArrayBuffer, base64ToObjectURL } from "../../../helpers/pdfUtil";

	export default {
        name: 'VisorDocumento',
        props: {            
            visibleModal: {
                type: Boolean,
                default: false
            },          
            isBase64: {
                type: Boolean,
                default: true
            },
            dataFile: null,            
        },
        components: {
            VuePdfApp,
        },
		data() {
			return {				
				position: 'center',
				visibleLeft: false,
				visibleRight: false,
				visibleTop: false,
				visibleBottom: false,
				visibleFull: false,		
                
                hasHeader: false,

                pdf: null,
                isPdf: false,
                
                isMobile: false,

                config: {
                    sidebar: false,
                    secondaryToolbar: false,
                    toolbar: {
                        toolbarViewerLeft: {
                            findbar: false,
                            previous: true,
                            next: true,
                            pageNumber: true,
                        },
                        toolbarViewerRight: {
                            presentationMode: true,
                            openFile: false,
                            print: true,
                            download: true,
                            viewBookmark: false,
                        },
                        toolbarViewerMiddle: {
                            zoomOut: true,
                            zoomIn: true,
                            scaleSelectContainer: false,
                        },
                    },
                    errorWrapper: true,

                },
                idConfig: { 
                    // print: "printId", 
                    download: "downloadId" 
                },

                dataUrl:null
			}
		},
		
		created() {
			
		},
		mounted() {
            this.getDevice();
            
            if(this.dataFile){
                if(this.dataFile.indexOf('application/pdf') >=0 || this.dataFile.indexOf('image') >=0){
                    if(this.dataFile.indexOf('application/pdf') >= 0){
                        this.isPdf = true;
                        this.getDocumento();
                        this.getUrlDocumento('application/pdf');
                    }else{
                        this.getUrlDocumento('image/jpeg');
                        this.isPdf = false;
                    }
                    this.hasHeader = true;
                }else{
                    this.getUrlDocumento('application/octet-stream');
                    this.hasHeader = false;
                }
            }

            
		},
        computed:{
            
        },
        watch: {
            dataFile : function(){
                if(this.dataFile){
                    if(this.dataFile.indexOf('application/pdf') >= 0 || this.dataFile.indexOf('image') >= 0){
                        if(this.dataFile.indexOf('application/pdf') >= 0){
                            this.isPdf = true;
                            this.getDocumento();
                            this.getUrlDocumento('application/pdf');
                        }else{
                            this.getUrlDocumento('image/jpeg');
                            this.isPdf = false;
                        }
                        this.hasHeader = true;
                    }else{
                        this.getUrlDocumento('application/octet-stream');
                        this.hasHeader = false;

                    }
                }
            }
        },
		methods: {
            getUrlDocumento(tipo){
                this.dataUrl = base64ToObjectURL(this.dataFile,tipo)
            },

            getDocumento(){
                this.pdf = base64ToArrayBuffer(this.dataFile);
            },		

			cerrarModal() {	
                this.$emit('close');
			},

            navegadorTienePluginPDF() {
                let pdfPlugin = document.createElement('object');
                pdfPlugin.type = 'application/pdf';

                const isPluginSupported = pdfPlugin.type !== 'application/pdf' || !!navigator.mimeTypes['application/pdf'];

                return isPluginSupported;
            },

            getDevice() {
                const userAgent = navigator.userAgent;
                // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                if (/Mobile/i.test(userAgent) || !this.navegadorTienePluginPDF()) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            }
		}
	}
</script>

<style>
    #modalVisor .p-dialog-header-close{
        display: none !important;
        visibility: hidden !important;
    }
    .pdf-viewer{
        height: 70vh;
    }
</style>