import { apiInstance } from "./ApiInstance";

class ModuloService{
    static lista(filtro = null){
        return apiInstance.get(`/modulo?filtro=${filtro}`);
    }

    static async modulosPrograma(id){
        try {
            const response = await apiInstance.get(`/modulo/programas/${id}`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async listaSelect(){
        try {
            const response = await apiInstance.get(`/modulo`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static registrar(modulo){
        return apiInstance.post(`/modulo`, modulo);
    }

    static actualizar(modulo, id){
        return apiInstance.patch(`/modulo/${id}`, modulo);
    }

    static async eliminar(id){
        try {
            const response = await apiInstance.delete(`/modulo/${id}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}

export { ModuloService }