import { apiInstance } from "./ApiInstance";

class ProgramaService {
    static async lista(){
        try {
            const response = await apiInstance.get(`/programa`);
            return response;
        } catch (error) {
            return error;
        }
    }

    static async getPrograma(id){
        try {
            const response = await apiInstance.get(`/programa/${id}`);
            return response;
        } catch (error) {
            return error;
        }
    }

}

export { ProgramaService }