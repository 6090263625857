import { apiInstance } from "./ApiInstance";

class PagoService{
    
    static async lista(filtros){
        try {
            let query = '';
            filtros.fechaInicio && filtros.fechaFin ? query += `?fechaInicio=${filtros.fechaInicio}&fechaFin=${filtros.fechaFin}` : query +='';
            // filtros.tipo ? query += `&tipo=${filtros.tipo}` : '';
            filtros.numero ? query += `&numero=${filtros.numero}` : '';
            // filtros.estado > 0 ? query += `&estado=${filtros.estado}` : '';

            const response = await apiInstance.get(`/pago/mis-pagos${query}`);
            return response;
        } catch (error) {
            return error;
        }
    }

    static registrarPago(data){
        return apiInstance.post(`/pago`, data);
    }

    static getPago(id){
        return apiInstance.get(`/pago/${id}`);
    }

    static actualizarPrimerPago(data, id){
        return apiInstance.patch(`/pago/actualizar-primer-pago/${id}`, data);
    }

    static getNoAdeudo(){
        return apiInstance.post(`/pago/imprimir/no-adeudo`);
    }

    static getPagosPrograma(inscripcionId){
        return apiInstance.post(`/pago/imprimir/consultar-deuda/${inscripcionId}`);
    }

    static getReportePagos(){
        return apiInstance.post(`/pago/imprimir/pagos-realizados`);
    }

    static getPagoInscripcion(inscripcionId){
        return apiInstance.get(`/pago/primer-pago/${inscripcionId}`);
    }
}

export { PagoService }