import * as yup from 'yup';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const registroSchema = yup.object().shape({
    correo: yup.string().matches(emailRegex, 'Escriba un correo válido').required('El correo es obligatorio'),
    sesionGoogle: yup.boolean(),
    
    password: yup.string().when('sesionGoogle', {
        is: false,
        then: (schema) => schema.min(6, 'La contraseña debe contener 6 caracteres como mínimo').required('El campo contraseña es obligatorio'),
        otherwise: (schema) => schema.min(0),
    }),
    existeCursante: yup.boolean(),
    confirmPassword: yup.string().when(['existeCursante', 'password'], {
        is: (existeCursante, password) => !existeCursante && password !== undefined,
        then: (schema) => schema.oneOf([yup.ref('password')], 'Las contraseñas deben coincidir').required('Por favor, repite la contraseña'),
        otherwise: (schema) => schema.notRequired(),
    })
});