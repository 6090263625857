import * as yup from 'yup';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const modalidadSchema = yup.object().shape({
    descuento: yup.mixed().required('El campo descuento es obligatorio'),
    cuotas: yup.number().min(1,'El número de cuotas debe ser mayor o igual a 1').required('El número de cuotas es obligatorio'),
    diaLimite: yup.number().min(1,'El día límite debe ser mayor o igual a 1').required('El día límite de pago es obligatorio'),

    // tipoDocumento: yup.mixed().required('El campo tipo documento es obligatorio'),
    // numeroDocumento: yup.string().required('El número de documento es obligatorio'),
    // razonSocial: yup.string().required('El campo razon social es obligatorio'),

    existeCorreo: yup.boolean(),
    correoFacturacion: yup.string().when('existeCorreo', {
        is: true,
        then: (schema) => schema.matches(emailRegex, 'Escriba un correo válido'),
        otherwise: (schema) => schema.nullable(true),
    }),
});