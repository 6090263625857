import { apiInstance } from "./ApiInstance";

class DocumentacionInscripcionService {
    
    static registrar(data){
        return apiInstance.post(`/documentacion-inscripcion`, data);
    }

    static async actualizar(data, id){
        try {
            const response = await apiInstance.patch(`/documentacion-inscripcion/${id}`, data);
            return response;
        } catch (error) {
            return error;
        }
    }

}

export { DocumentacionInscripcionService }