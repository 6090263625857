import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import router from './router';

import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';

import AutoComplete from 'primevue/autocomplete';
// import Accordion from 'primevue/accordion';

// import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
// import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
// import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
// import CascadeSelect from 'primevue/cascadeselect';
// import Carousel from 'primevue/carousel';
// import Chart from 'primevue/chart';
// import Checkbox from 'primevue/checkbox';
// import Chip from 'primevue/chip';
// import Chips from 'primevue/chips';
// import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';

import ConfirmDialog from 'primevue/confirmdialog';
// import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
// import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
// import DataView from 'primevue/dataview';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
// import Fieldset from 'primevue/fieldset';
// import FileUpload from 'primevue/fileupload';
// import FullCalendar from 'primevue/fullcalendar';
// import Galleria from 'primevue/galleria';
// import Image from 'primevue/image';
// import InlineMessage from 'primevue/inlinemessage';
// import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
// import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
// import Knob from 'primevue/knob';
// import Listbox from 'primevue/listbox';
// import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
// import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
// import MultiSelect from 'primevue/multiselect';
// import OrderList from 'primevue/orderlist';
// import OrganizationChart from 'primevue/organizationchart';
// import OverlayPanel from 'primevue/overlaypanel';
// import Paginator from 'primevue/paginator';
// import Panel from 'primevue/panel';
// import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
// import PickList from 'primevue/picklist';
// import ProgressBar from 'primevue/progressbar';
// import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
// import SelectButton from 'primevue/selectbutton';
// import ScrollPanel from 'primevue/scrollpanel';
// import ScrollTop from 'primevue/scrolltop';
// import Skeleton from 'primevue/skeleton';
// import Slider from 'primevue/slider';
// import Sidebar from 'primevue/sidebar';
// import SpeedDial from 'primevue/speeddial';
// import SplitButton from 'primevue/splitbutton';
// import Splitter from 'primevue/splitter';
// import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
// import TabMenu from 'primevue/tabmenu';
// import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
// import Toolbar from 'primevue/toolbar';
// import TabView from 'primevue/tabview';
// import TabPanel from 'primevue/tabpanel';
// import Tag from 'primevue/tag';
// import Timeline from 'primevue/timeline';
// import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
// import Tree from 'primevue/tree';
// import TreeSelect from 'primevue/treeselect';
// import TreeTable from 'primevue/treetable';
// import TriStateCheckbox from 'primevue/tristatecheckbox';

// import CodeHighlight from './AppCodeHighlight';
// import BlockViewer from './BlockViewer.vue';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
// import './assets/demo/flags/flags.css';

// import MpTemplatesPlugin from 'mp-vue-editor';
// import CKEditor from '@ckeditor/ckeditor5-vue'
// import '../node_modules/mp-vue-editor/dist/style.css';

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';


import Permissions from './mixins/Permissions';

import vue3GoogleLogin from 'vue3-google-login';

const app = createApp({
	render () { return h(AppWrapper) }
});

app.use(createPinia());

app.mixin(Permissions);

app.use(vue3GoogleLogin, {
	clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
});

// app.use(CKEditor);
// app.use(MpTemplatesPlugin, {
// 	//Si su proyecto no usa los servicios de primevue true | false
// 	useServices: true,
// 	//Si su proyecto no usa las directivas de primevue true | false
// 	addDirectives: true,
// 	//Si su proyecto no usa los componentes de primevue true | false
// 	addPrimeVueComponents: true,
// });

let es = {
	firstDayOfWeek: 1,
	dayNames: [
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado",
	],
	dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
	dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
	monthNames: [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	],
	monthNamesShort: [
		"Ene",
		"Feb",
		"Mar",
		"Abr",
		"May",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	],
	today: "Hoy",
	clear: "Borrar",
	weekHeader: "Sm",
}

app.use(PrimeVue, { 
	locale: es, 
	ripple: true , 
	inputStyle: 'filled',
});

app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
// app.directive('code', CodeHighlight);

// app.component('Accordion', Accordion);
// app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
// app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
// app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
// app.component('Carousel', Carousel);
// app.component('CascadeSelect', CascadeSelect);
// app.component('Chart', Chart);
// app.component('Checkbox', Checkbox);
// app.component('Chip', Chip);
// app.component('Chips', Chips);
// app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
// app.component('ConfirmPopup', ConfirmPopup);
// app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
// app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
// app.component('Fieldset', Fieldset);
// app.component('FileUpload', FileUpload);
// app.component('FullCalendar', FullCalendar);
// app.component('Galleria', Galleria);
// app.component('Image', Image);
// app.component('InlineMessage', InlineMessage);
// app.component('Inplace', Inplace);
// app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
// app.component('Knob', Knob);
// app.component('Listbox', Listbox);
// app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
// app.component('Menubar', Menubar);
app.component('Message', Message);
// app.component('MultiSelect', MultiSelect);
// app.component('OrderList', OrderList);
// app.component('OrganizationChart', OrganizationChart);
// app.component('OverlayPanel', OverlayPanel);
// app.component('Paginator', Paginator);
// app.component('Panel', Panel);
// app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
// app.component('PickList', PickList);
// app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
// app.component('Rating', Rating);
// app.component('SelectButton', SelectButton);
// app.component('ScrollPanel', ScrollPanel);
// app.component('ScrollTop', ScrollTop);
// app.component('Slider', Slider);
// app.component('Sidebar', Sidebar);
// app.component('Skeleton', Skeleton);
// app.component('SpeedDial', SpeedDial);
// app.component('SplitButton', SplitButton);
// app.component('Splitter', Splitter);
// app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
// app.component('TabMenu', TabMenu);
// app.component('TabView', TabView);
// app.component('TabPanel', TabPanel);
// app.component('Tag', Tag);
app.component('Textarea', Textarea);
// app.component('TieredMenu', TieredMenu);
// app.component('Timeline', Timeline);
app.component('Toast', Toast);
// app.component('Toolbar', Toolbar);
// app.component('ToggleButton', ToggleButton);
// app.component('Tree', Tree);
// app.component('TreeSelect', TreeSelect);
// app.component('TreeTable', TreeTable);
// app.component('TriStateCheckbox', TriStateCheckbox);

// app.component('BlockViewer', BlockViewer);
app.component('VueSelect', vSelect);

app.mount('#app');
