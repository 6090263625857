import { apiInstance } from "./ApiInstance";

class ProgramaPlantillaService{
    static lista(filtro = null){
        return apiInstance.get(`/programa-plantilla?filtro=${filtro}`);
    }

    static async listaSelect(){
        try {
            const response = await apiInstance.get(`/programa-plantilla`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static registrar(data){
        return apiInstance.post(`/programa-plantilla`, data);
    }

    static actualizar(data, id){
        return apiInstance.patch(`/programa-plantilla/${id}`, data);
    }

    static async eliminar(id){
        try {
            const response = await apiInstance.delete(`/programa-plantilla/${id}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}

export { ProgramaPlantillaService }