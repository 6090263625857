import { apiInstance } from "./ApiInstance";

class ProgramaDestinatarioService{
    static lista(filtro){
        return apiInstance.get(`/programa-destinatario?filtro=${filtro}`);
    }

    static async get(id){
        try {
            const response = await apiInstance.get(`/programa-destinatario/${id}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async getProgramaDestinatario(programaId){
        try {
            const response = await apiInstance.get(`/programa-destinatario/programa/${programaId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async getProgramaDestinatarioPlantillas(programaId){
        try {
            const response = await apiInstance.get(`/programa-destinatario/programa/${programaId}/plantillas`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static registrar(data){
        return apiInstance.post(`/programa-destinatario`, data);
    }

    static async eliminar(id){
        try {
            const response = await apiInstance.delete(`/programa-destinatario/${id}`);
            if(response.data){
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}

export { ProgramaDestinatarioService }