import { apiInstance } from "./ApiInstance";

class ReporteService{
    static getReporte(id, jsonData){
        return apiInstance.post(`/reporte/imprimir/${id}`, jsonData);
    }

    static getReporteBase64(id, jsonData){
        return apiInstance.post(`/reporte/render/${id}`, jsonData);
    }

    static render(data){
        return apiInstance.post(`/reporte/render`, data);
    }

}

export { ReporteService }