import { apiInstance } from "./ApiInstance";

class ProgramaDescuentoService{
    
    static async getDescuentos(destinatarioId){
        try {
            const response = await apiInstance.get(`/programa-descuentos/programa-destinatario/${destinatarioId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

}

export { ProgramaDescuentoService }