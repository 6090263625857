import { apiInstance } from "./ApiInstance";

class RequisitoService{
    
    static async getRequisitos(destinatarioId){
        try {
            const response = await apiInstance.get(`/requisito/${destinatarioId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async requisitosGenerales(destinatarioId){
        try {
            const response = await apiInstance.get(`/requisito/persona-requisitos/${destinatarioId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static getRequisito(id){
        return apiInstance.get(`/persona-documento/${id}`);
    }

    static subirRequisito(data){
        return apiInstance.post(`/persona-documento`, data);
    }

    static actualizarRequisito(data, id){
        return apiInstance.patch(`/persona-documento/${id}`, data);
    }

    static async listaInscripcion(inscripcionId){
        try {
            const response = await apiInstance.get(`/documento-requerido-programa/inscripcion/${inscripcionId}`);
            if(response.data){  
                return response.data;
            }else{
                return null;
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }

}

export { RequisitoService }