// import { isString } from '@vue/shared'
import { DateTime } from 'luxon'

const getDateFormatHumano = (date) => {
  try {
    let data = date.toISOString().replace('Z', '')
    let _date = DateTime.fromISO(data)
    return _date.setLocale('es').toFormat("dd 'de' MMMM 'de' yyyy")
  } catch {
    return ''
  }
}

const getDate = (date) => {
  try {
    let data = date.replace('Z', '')
    let _date = DateTime.fromISO(data)
    return _date.toFormat('dd/MM/yyyy H:mm:ss')
  } catch {
    return ''
  }
}

const getDateDMY = (date) => {
  try {
    let data = date.replace('Z', '')
    let _date = DateTime.fromISO(data)
    return _date.toFormat('dd/MM/yyyy')
  } catch {
    return ''
  }
}

const getDateDMYJS = (data) => {
  let result = DateTime.fromJSDate(data).toFormat('dd/MM/yyyy')
  return result;
}

const getDateFromDB = (date) => {
  try {
    const data = DateTime.fromISO(date);
    return data.toFormat('MM/dd/yyyy');
  } catch {
    return ''
  }
}

const getDateTime = (date) => {
  try {
    // console.log(new Date(date).toLocaleDateString())
    let data = date.replace('Z', '')
    return DateTime.fromISO(data)
  } catch {
    return null
  }
}

const getDateAndTime = () => {
  return {
    time: DateTime.now().toFormat('hh:mm'),
    date: DateTime.now().toFormat('dd/MM/yyyy'),
  }
}

const formatDateTime = (date) => {
  try {
    let result = DateTime.fromJSDate(date).toFormat('dd/MM/yyyy H:mm:ss')
    if (result == 'Invalid DateTime') return null
    return result
  } catch (e) {
    return null
  }
}

const formatDate = (date) => {
  try {
    let result = DateTime.fromJSDate(date).toFormat('dd/MM/yyyy')
    if (result == 'Invalid DateTime') return null
    return result
  } catch (e) {
    // var parts = date.split('-')
    // var mydate = new Date(parts[0], parts[1] - 1, parts[2])
    return null
  }
}

const strToDate = (date) => {
  if(date){
    date = String(date)
    const parts = date.split('-')

    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }else{
    return null
  }

}

const formatDateYMD = (date) => {
  try {
    let result = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
    if (result == 'Invalid DateTime') return null
    return result
  } catch (e) {
    return null
  }
}

const formatDateDMY = (date) => {
  const d = new Date(date)
  d.setDate(d.getDate() + 0)
  return d
}

/**
 * @param {object} data
 * @param {object} data.minutes
 * @param {object} data.days
 * @param {object} data.hours
 * @return {DateTime}
 **/
const getDateTimeNow = (data) => {
  if (data) return DateTime.now().plus(data)
  else return DateTime.now()
}

/**
 * @param {object} data
 * @param {object} data.seconds
 * @return {DateTime}
 **/

const addDateTime = (date, data) => {
  return date.plus(data)
}

const addDateDays = (date, days) => {
  const d = new Date(date)
  d.setDate(d.getDate() + days)
  return d
}

/**
 * @param {object} data
 * @param {object} data.fecha_inicio
 * @param {object} data.fecha_fin
 * @return {Object}
 **/
const getBetweenDate = ({ fecha_inicio, fecha_fin }) => {
  try {
    let result = null
    if (fecha_fin.diff(fecha_inicio) > 0) {
      result = fecha_fin.diff(fecha_inicio, ['days', 'hours', 'minutes', 'seconds']).toObject()
      for (let [key, value] of Object.entries(result)) {
        let convert = parseInt(value)
        if (convert > 9) result[key] = `${convert}`
        else result[key] = `0${convert}`
      }
    }
    return result
  } catch (e) {
    // console.log(e)
    return null
  }
}

const getDays = (fecha_ini, fecha_fin) => {
  let fechaIni = fecha_ini.replace('Z', '').replace('T', ' ').substr(0, 10)
  let fechaFin = fecha_fin.replace('Z', '').replace('T', ' ').substr(0, 10)
  var end = DateTime.fromISO(fechaFin)
  var start = DateTime.fromISO(fechaIni)

  // console.log(fechaIni + ' ' + fechaFin)

  var diffInDays = end.diff(start, 'days') // ['months', 'days']
  return diffInDays.toObject() //=> { days: 1 }
}

export {
  getDate,
  getDateTime,
  getDateFormatHumano,
  getDateAndTime,
  addDateDays,
  formatDateTime,
  formatDate,
  strToDate,
  formatDateYMD,
  formatDateDMY,
  getDateTimeNow,
  getBetweenDate,
  addDateTime,
  getDays,
  getDateDMY,
  getDateFromDB, 
  getDateDMYJS
}
