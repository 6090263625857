<template>
	<div v-show="load">	
		<div v-if="programa" class="layout-wrapper-content">
			<div class="layout-main lg:mx-8">	
				<div class="grid ">
					<div class="col-12 md:col-12">		
						<div id="form-inscripcion" class="card p-fluid md:p-5 md:m-5 p-2 m-2">
							<div v-if="authStore.isTokenValid()" class="col-12 flex justify-content-end">
								<div class="flex align-items-center">
									<Avatar :label="authStore.getUser?.nombres[0] || 'N'" class="mr-2" size="large" shape="circle"></Avatar>
									<p class>
										<strong>{{ authStore.getUser?.nombres }} {{ authStore.getUser?.paterno }} {{ authStore.getUser?.materno }}</strong> 
										<br/>
										<span>{{ authStore.getUser?.email }}</span>
									</p>
									<Button icon="pi pi-sign-out" class="p-button-danger ml-4" @click="logout" v-tooltip="'Cerrar Sesión'"/>
								</div>								
							</div>
							<div class="col-12">
								<p class="text-center"><img class="img-thumbnail" style="height: 6rem;" src="/images/logo-cepi-web-dark.png" alt="logo"></p>
								<h2 class="title uppercase font-bold text-primary text-center">Formulario de Inscripción</h2>
								<h3 class="title-section text-center">{{ programa?.nombre }} - {{ version }} versión</h3>
								<h4 class="title-section text-center">Modalidad {{ programa?.modalidad?.nombre }}</h4>
								
								<Message severity="info" :closable="false" class="message-inscripcion">
									<p>El presente Formulario es indispensable para la incripción a los Programas del C.E.P.I.</p>
									<p><b>Nota:</b> Si los datos que requiere no se encuentran en el formulario tales como universidad o profesión, envíe un correo electrónico a la dirección: cepi@cepi.edu.bo, especificando los datos que necesita.</p>
									<p>Los campos marcados con <span class="color-danger">[*]</span> son obligatorios.</p>
								</Message>
							</div>
							
							<Steps class="mb-4" v-model:activeStep="activeIndex" :model="items" :readonly="true"/>

							<div class="flex justify-content-between flex-wrap mb-4">
								<div>
									<Button v-if="activeContent != 'datos'" type="button" class="p-button-outlined" label="Anterior" @click="clickBack(activeContent)" icon="pi pi-arrow-left"/>
								</div>
								<div>
									<Button v-if="activeContent == 'finalizar'" class="w-max" :loading="isLoading" label="Finalizar" @click="clickNext(activeContent)" icon="pi pi-check" iconPos="right"/>
									<Button v-else class="w-min" label="Siguiente" :loading="isLoading" @click="clickNext(activeContent)" icon="pi pi-arrow-right" iconPos="right"/>    
								</div>
							</div>

							<!-- Paso 1 - Registro de Datos -->
							<div v-if="activeContent == 'datos'" class="grid p-3 step-content datos" :class="{'active-content': activeContent === 'datos'}">
								<div class="grid">
									<div class="col-12 md:col-8">
										<h5 v-if="tiposParticipante.length > 1"><span class="color-danger">[*]</span> Seleccione el Tipo Participante</h5>
										<div v-if="tiposParticipante.length > 1" class="grid">
											<div class="field col-12 md:col-3">	
												<!-- <Dropdown v-model="formDatos.tipoParticipante" :options="tiposParticipante" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.tipoParticipante" :options="tiposParticipante" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.tipoParticipante" class="p-error" >{{ erroresDatos.tipoParticipante }}</small>
											</div>					
										</div>
										<h5>Datos Personales</h5>
										<div class="grid formgrid">
											<div class="field col-6 md:col-3" >
												<label for="documento" v-tooltip.top="'Escriba su Nro. de Identificación seguido del Complemento si corresponde. Ej: 5577886-1A'"><span class="color-danger">[*]</span> No. de Identificación <i class="pi pi-info-circle color-warning"></i></label>
												<InputText v-model="formDatos.documento" id="documento" @keyup="buscarPersona" type="text" v-tooltip.top="'Escriba su Nro. de Identificación seguido del Complemento si corresponde. Ej: 5577886-1A'"/>
												<small v-if="erroresDatos?.documento" class="p-error" >{{ erroresDatos.documento }}</small>					
											</div>
											<!-- <div class="field col-12 md:col-1">
												<label for="complemento">Complemento</label>
												<InputText v-model="formDatos.complemento" id="documento" type="text"/>
												<small v-if="erroresDatos?.complemento" class="p-error" >{{ erroresDatos.complemento }}</small>					
											</div> -->
											<div class="field col-6 md:col-2">	
												<label for="expedido"><span class="color-danger">[*]</span> Expedido en</label>                        
												<!-- <Dropdown v-model="formDatos.expedido" :options="departamentos" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.expedido" :options="departamentos" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.expedido" class="p-error" >{{ erroresDatos.expedido }}</small>
											</div>
											<div class="field col-6 md:col-3">
												<label for="nombres"><span class="color-danger">[*]</span> Nombres</label>                        
												<InputText v-model="formDatos.nombres" id="nombres" type="text" />							
												<small v-if="erroresDatos?.nombres" class="p-error" >{{ erroresDatos.nombres }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="apellidoPaterno"><span class="color-danger">[*]</span> Apellido Paterno</label>                        
												<InputText v-model="formDatos.apellidoPaterno" id="apellidoPaterno" type="text" />							
												<small v-if="erroresDatos?.apellidoPaterno" class="p-error" >{{ erroresDatos.apellidoPaterno }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="apellidoMaterno"><span class="color-danger">[*]</span> Apellido Materno</label>                        
												<InputText v-model="formDatos.apellidoMaterno" id="apellidoMaterno" type="text" />							
												<small v-if="erroresDatos?.apellidoMaterno" class="p-error" >{{ erroresDatos.apellidoMaterno }}</small>
											</div>
											<div class="field col-6 md:col-3">	
												<label for="sexo"><span class="color-danger">[*]</span> Sexo</label>                        
												<!-- <Dropdown v-model="formDatos.sexo" :options="sexoOpciones" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.sexo" :options="sexoOpciones" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.sexo" class="p-error" >{{ erroresDatos.sexo }}</small>
											</div>					                    
										<!-- </div>
										<h5>Lugar y Fecha de Nacimiento</h5>
										<div class="grid formgrid"> -->
											<div class="field col-6 md:col-2">
												<label for="paisOrigen"><span class="color-danger">[*]</span> País de Origen</label>
												<!-- <Dropdown v-model="formDatos.paisOrigen" :options="paises" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.paisOrigen" :options="paises" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.paisOrigen" class="p-error" >{{ erroresDatos.paisOrigen }}</small>
											</div>
											<div class="field col-6 md:col-3">
												<label for="departamentoOrigen"><span class="color-danger">[*]</span> Departamento de Origen</label>
												<!-- <Dropdown v-model="formDatos.departamentoOrigen" :options="departamentos" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.departamentoOrigen" :options="departamentos" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.departamentoOrigen" class="p-error" >{{ erroresDatos.departamentoOrigen }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="fechaNacimiento"><span class="color-danger">[*]</span> Fecha de Nacimiento</label>
												<Calendar v-model="formDatos.fechaNacimiento" dateFormat="dd/mm/yy" :maxDate="fechaHoy" :showIcon="true" :showButtonBar="true"/>
												<!-- <InputMask v-model="formDatos.fechaNacimiento" id="fechaNacimiento" placeholder="99/99/9999" mask="99/99/9999" slotChar="dd/mm/yyyy" /> -->
												<small v-if="erroresDatos?.fechaNacimiento" class="p-error" >{{ erroresDatos.fechaNacimiento }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="estadoCivil"><span class="color-danger">[*]</span> Estado Civil</label>
												<!-- <Dropdown v-model="formDatos.estadoCivil" :options="estadosCivil" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.estadoCivil" :options="estadosCivil" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.estadoCivil" class="p-error" >{{ erroresDatos.estadoCivil }}</small>
											</div>
										</div>
										<h5>Datos de Residencia</h5>
										<div class="grid formgrid">
											<div class="field col-6 md:col-2">
												<label for="paisResidencia"><span class="color-danger">[*]</span> País</label>
												<!-- <Dropdown v-model="formDatos.paisResidencia" :options="paises" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.paisResidencia" :options="paises" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.paisResidencia" class="p-error" >{{ erroresDatos.paisResidencia }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="departamentoResidencia"><span class="color-danger">[*]</span> Departamento</label>
												<!-- <Dropdown v-model="formDatos.departamentoResidencia" :options="departamentos" @change="getMunicipios" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.departamentoResidencia" :options="departamentos" @option:selected="getMunicipios" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.departamentoResidencia" class="p-error" >{{ erroresDatos.departamentoResidencia }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="municipioResidencia"><span class="color-danger">[*]</span> Municipio</label>
												<!-- <Dropdown v-model="formDatos.municipioResidencia" :options="municipios" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.municipioResidencia" :options="municipios" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.municipioResidencia" class="p-error" >{{ erroresDatos.municipioResidencia }}</small>
											</div>

											<div class="field col-6 md:col-4">
												<label for="direccion"><span class="color-danger">[*]</span> Dirección</label>
												<InputText v-model="formDatos.direccion" id="direccion" type="text" />
												<small v-if="erroresDatos?.direccion" class="p-error" >{{ erroresDatos.direccion }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="telefono">Teléfono</label>
												<InputText v-model="formDatos.telefono" id="telefono" type="text" />
												<small v-if="erroresDatos?.telefono" class="p-error" >{{ erroresDatos.telefono }}</small>
											</div>
											<div class="field col-6 md:col-2">
												<label for="celular"><span class="color-danger">[*]</span> Celular</label>
												<InputText v-model="formDatos.celular" id="celular" type="text" />
												<small v-if="erroresDatos?.celular" class="p-error" >{{ erroresDatos.celular }}</small>
											</div>
											<div class="field col-6 md:col-4">
												<label for="correo"><span class="color-danger">[*]</span> Correo</label>
												<InputText v-model="formDatos.correo" id="correo" type="text" />
												<small v-if="erroresDatos?.correo" class="p-error" >{{ erroresDatos.correo }}</small>
											</div>						
										</div>
										<h5>Formación Académica</h5>
										<div class="grid formgrid">
											<div class="field col-12 md:col-8">
												<label for="universidad"><span class="color-danger">[*]</span> Universidad</label>
												<!-- <AutoComplete v-model="formDatos.universidad" placeholder="Buscar..." :dropdown="true" :multiple="false" :suggestions="universidadesFiltrado" @complete="buscarUniversidades($event)" field="nombre"/> -->
												<VueSelect v-model="formDatos.universidad" :options="universidades" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.universidad" class="p-error" >{{ erroresDatos.universidad }}</small>
											</div>
											<div class="field col-6 md:col-4">
												<label for="areaEstudio"><span class="color-danger">[*]</span> Area de Estudio</label>
												<!-- <Dropdown v-model="formDatos.areaEstudio" :options="areasEstudio" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.areaEstudio" :options="areasEstudio" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.areaEstudio" class="p-error" >{{ erroresDatos.areaEstudio }}</small>
											</div>
											<div class="field col-6 md:col-4">
												<label for="gradoObtenido"><span class="color-danger">[*]</span> Grado Obtenido</label>
												<!-- <AutoComplete v-model="formDatos.gradoObtenido" placeholder="Buscar..." :dropdown="true" :multiple="false" :suggestions="gradosAcademicosFiltrado" @complete="buscarGrados($event)" field="nombre"/> -->
												<VueSelect v-model="formDatos.gradoObtenido" :options="gradosAcademicos" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresDatos?.gradoObtenido" class="p-error" >{{ erroresDatos.gradoObtenido }}</small>
											</div>
											<div class="field col-6 md:col-4">
												<label for="fechaDiploma"><span class="color-danger">[*]</span> Fecha Obtención de Diploma Académico</label>
												<Calendar v-model="formDatos.fechaDiploma" dateFormat="dd/mm/yy" :maxDate="fechaHoy" :showIcon="true" :showButtonBar="true"></Calendar>						
												<small v-if="erroresDatos?.fechaDiploma" class="p-error" >{{ erroresDatos.fechaDiploma }}</small>
											</div>
											<div class="field col-6 md:col-4">
												<label for="fechaTitulo"><span class="color-danger">[*]</span> Fecha Obtención de Título en Provisión Nacional</label>
												<Calendar v-model="formDatos.fechaTitulo" dateFormat="dd/mm/yy" :maxDate="fechaHoy" :showIcon="true" :showButtonBar="true"></Calendar>						
												<small v-if="erroresDatos?.fechaTitulo" class="p-error" >{{ erroresDatos.fechaTitulo }}</small>
											</div>					
										</div>
									</div>
									<div class="col-12 lg:pl-8 lg:pr-8 md:col-4">
										<template v-if="authStore.isTokenValid()">
											<h5>Datos de la Sesión</h5>
											<Message severity="info" :closable="false" class="message-inscripcion">
												<p>Usted tiene una sesión activa como:</p>
											</Message>
											<div class="grid formgrid">
												<div class="field col-12 md:col-12">
													<strong>{{ authStore.getUser?.nombres }} {{ authStore.getUser?.paterno }} {{ authStore.getUser?.materno }}</strong>
												</div>
												<div class="field col-12 md:col-12">
													<span>{{ authStore.getUser?.email }}</span>
												</div>
												<div class="field col-12 md:col-12">
													<Button label="Cerrar Sesión" icon="pi pi-sign-out" class="p-button-danger w-auto" @click="logout"></Button>
												</div>
											</div>
										</template>
										<template v-else>
											<h5>Identificación / Registro</h5>
											<div class="grid formgrid">										
												<div class="field col-12 md:col-12">
													<Message severity="warn" :closable="false" class="message-inscripcion text-center">
														<p>
															Para crear una cuenta en nuestro sistema, debe escribir una contraseña segura que pueda recordar y luego presionar el botón <strong>Siguiente</strong>. 
														</p>
														<p>ó</p>
														<p>
															Si se registró anteriormente en nuestro sistema, identifíquese verificando su correo y la contraseña creada.
														</p>
													</Message>
												</div>										
												<div class="field col-12 md:col-12">
													<label for="correo"><span class="color-danger">[*]</span> Correo</label>
													<InputText v-model="formDatos.correo" type="text" :class="{ 'p-invalid': erroresRegistro?.correo }" disabled/>
													<small v-if="erroresRegistro?.correo" class="p-error" >{{ erroresRegistro.correo }}</small>
												</div>
												<div class="field col-12 md:col-12">
													<label for="password"><span class="color-danger">[*]</span> Contraseña</label>                        
													<Password v-model="formDatos.password" :class="{ 'p-invalid': erroresRegistro?.password }" :feedback="false" toggleMask></Password>							
													<small v-if="erroresRegistro?.password" class="p-error" >{{ erroresRegistro.password }}</small>
												</div>
												<div v-if="!existeCursante" class="field col-12 md:col-12">
													<label for="confirmPassword"><span class="color-danger">[*]</span> Confirmar la Contraseña</label>                        
													<Password v-model="formDatos.confirmPassword" :class="{ 'p-invalid': erroresRegistro?.confirmPassword }" :feedback="false" toggleMask></Password>							
													<small v-if="erroresRegistro?.confirmPassword" class="p-error" >{{ erroresRegistro.confirmPassword }}</small>
												</div>										
											</div>
										</template>										
									</div>
								</div>														
							</div>

							<!-- Paso 3 - Modalidad de Pago -->
							<div v-if="activeContent == 'modalidad'" class="grid p-3 step-content datos" :class="{'active-content': activeContent === 'modalidad'}">
								<div class="grid">
									<div class="col-12 md:col-6">
										<h5>Modalidad de pago</h5>
										<Message severity="info" :closable="false" class="message-inscripcion">
											<p>Por defecto la modalidad de pago es por cuotas y sin descuentos, una vez que se verifique la documentación enviada y la aplicabilidad del descuento se procederá a agregar una adenda al contrato a cargo de la Unidad Jurídica del CEPI</p>
										</Message>
										<div class="grid mt-2 mb-4">
											<div class="col-6">
												<strong>Costo Total: </strong> {{ formDatos.tipoParticipante.costoTotal }} Bs.
											</div>
											<div class="col-6">
												<strong>Costo Matrícula: </strong> {{ formDatos.tipoParticipante.costoMatricula }} Bs.
											</div>
											<div class="col-6">
												<strong>Costo Colegiatura: </strong> {{ formDatos.tipoParticipante.costoColegiatura }} Bs.
											</div>
											<div class="col-6">
												<strong>Costo Defensa / Tutoría: </strong> {{ formDatos.tipoParticipante.costoDefensaTutoria }} Bs.
											</div>
										</div>
										<div class="grid formgrid">
											<div class="field col-6 md:col-7 flex flex-column align-items-start justify-content-center">
												<span class="text-info">Seleccione la modalidad de pago que desea utilizar</span>
											</div>
											<div class="field col-6 md:col-5">
												<label for="modalidadContado">Modalidad de Pago</label>
												<div class="flex mt-2">
													<!-- <label for="modalidadContado">Al Contado</label>
													<InputSwitch v-model="formDatos.pagoContado" inputId="modalidadContado" @change="cambiarModalidadPago(true, false)" class="ml-2"/>
													<label for="modalidadCuotas" class="ml-3">Por Cuotas</label>
													<InputSwitch v-model="formDatos.pagoCuotas" inputId="modalidadCuotas" @change="cambiarModalidadPago(false, true)" class="ml-2"/> -->
													<div class="field-radiobutton mr-4" v-if="programa?.pagoCuotas !== false">
														<RadioButton id="cuotas" name="modalidadPago" value="Cuotas" v-model="formDatos.modalidadPago" @change="cambiarModalidadPago" />
														<label for="cuotas">Por Cuotas</label>
													</div>
													<div class="field-radiobutton" v-if="programa?.pagoContado !== false">
														<RadioButton id="contado" name="modalidadPago" value="Contado" v-model="formDatos.modalidadPago" @change="cambiarModalidadPago" />
														<label for="contado">Al Contado</label>
													</div>													
												</div>
											</div>
										</div>
										<div class="grid">
											<div class="field col-6 md:col-7 flex flex-column align-items-start justify-content-center">
												<span class="text-help">Seleccione un tipo de descuento y adjunte el respaldo correspondiente si corresponde.</span>
											</div>
											<div class="field col-6 md:col-4">
												<label for="gradoObtenido">Descuentos</label>
												<!-- <Dropdown v-model="formDatos.descuento" :options="descuentos" optionLabel="descripcion" @change="seleccionarDescuento" placeholder="- Seleccionar -"></Dropdown> -->
												<VueSelect v-model="formDatos.descuento" :options="descuentos" label="descripcion" @option:selected="seleccionarDescuento" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresModalidad?.descuentos" class="p-error" >{{ erroresModalidad.descuentos }}</small>
											</div>
										</div>
										
										<!-- <input type="file" ref="respaldo" style="display: none" accept=".pdf, image/*" @change="leerRespaldo"/>
										<div v-for="(documento, index) in formDatos.descuento?.datosVerificacion?.config?.documentos" :key="index" class="grid">
											<div class="field col-6 md:col-7 flex flex-column align-items-end justify-content-center">
												{{ documento }} 
											</div>
											<div class="field col-6 md:col-4">
												<Button @click="adjuntarRespaldo(documento)" class="p-button-primary w-auto" label="Adjuntar" />																		
												<Button v-if="existeRespaldo(documento)" icon="pi pi-eye" class="p-button-rounded p-button-warning ml-2" @click="verDocumentoRespaldo(documento)" v-tooltip.top="'Ver Documento'" />
											</div>
										</div> -->
										
										<div class="grid">
											<div class="field col-6 md:col-7 flex flex-column align-items-start justify-content-center">
												<span class="text-info">Seleccione el número de cuotas que Ud. desea utilizar para cancelar el costo total del programa</span>
											</div>
											<div class="field col-6 md:col-4">
												<label for="cuotas">No. de Cuotas (Max. {{ (maximoCuotas) }})</label>
												<InputNumber v-model="formDatos.cuotas" showButtons mode="decimal" @update:modelValue="getPlanPagos" @keyup="validarCuotas" :min="1" :max="maximoCuotas"></InputNumber>
												<small v-if="erroresModalidad?.cuotas" class="p-error" >{{ erroresModalidad.cuotas }}</small>
											</div>
										</div>
										
										<div class="grid">
											<div class="field col-6 md:col-7 flex flex-column align-items-start justify-content-center">
												<span class="text-info">Seleccione el día límite de pago de cada mes. Por ejemplo, si Ud. elige {{ programa.diaLimitePago }}, la cuota de cada mes deberá realizarla máximo hasta el día {{ programa.diaLimitePago }} de cada mes</span>
											</div>
											<div class="field col-6 md:col-4">
												<label for="diaLimite">Día límite de pago (Max. {{ programa.diaLimitePago }})</label>
												<InputNumber v-model="formDatos.diaLimite" showButtons mode="decimal" :min="1" :max="programa.diaLimitePago"></InputNumber>
												<small v-if="erroresModalidad?.diaLimite" class="p-error" >{{ erroresModalidad.diaLimite }}</small>
											</div>
										</div>
										<Message v-if="planPagos" severity="warn" :closable="false" class="message-inscripcion">
											<p v-if="formDatos.modalidadPago == 'Cuotas'">
												<strong>El pago inicial debe ser de: </strong>{{ planPagos?.costoPrimerPago }} Bs. por concepto de Matrícula {{ programa.nivelProgramaId == 1 ? 'más la primera cuota' : '' }}
											</p>
											<!-- <p v-else>
												<strong>Por concepto de Matrícula: </strong>{{ planPagos?.costoPrimerPago }} Bs.
											</p> -->

											<p v-if="formDatos.modalidadPago == 'Cuotas'">
												<strong>{{ programa.nivelProgramaId == 1 ? planPagos.nroCuotas-1 : planPagos.nroCuotas }} Cuotas restantes de: </strong>{{ planPagos?.costoColegiaturaCuotas  }} Bs.
											</p>
											<p v-else>
												<!-- <strong>Costo Matrícula: </strong>{{ planPagos?.costoMatricula }} Bs.<br/> -->
												<!-- <strong>Costo Colegiatura: </strong>{{ planPagos?.costoColegiaturaDescuento }} Bs.<br/> -->
												<strong>Monto de pago: </strong>{{ (planPagos?.costoMatricula + planPagos?.costoColegiaturaDescuento)  }} Bs.
											</p>
										</Message>
									</div>
									<div class="col-12 md:col-6 md:pl-8">
										<h5>Datos de Facturación</h5>
										<Message severity="info" :closable="false" class="message-inscripcion">
											<p>Llene los siguientes campos para la emisión de la factura</p>
										</Message>
										<div class="grid formgrid">
											<div class="field col-12 md:col-6">
												<label for="tipoDocumento">Tipo Documento</label>
												<!-- <Dropdown v-model="formDatos.tipoDocumento" :options="tiposDocumento" optionLabel="nombre" placeholder="- Seleccionar -"></Dropdown>									 -->
												<VueSelect v-model="formDatos.tipoDocumento" :options="tiposDocumento" label="nombre" placeholder="- Seleccionar -"></VueSelect>
												<small v-if="erroresModalidad?.tipoDocumento" class="p-error" >{{ erroresModalidad.tipoDocumento }}</small>
											</div>
											<div v-if="formDatos.tipoDocumento?.id == 1" class="field col-6 md:col-6">
												<label for="complemento">Complemento</label>
												<InputText v-model="formDatos.complemento" id="correo" type="text" />
												<small v-if="erroresModalidad?.complemento" class="p-error" >{{ erroresModalidad.complemento }}</small>
											</div>
										</div>
										<div class="grid formgrid">
											<div class="field col-12 md:col-6">
												<label for="numeroDocumento">Número Documento</label>
												<InputText v-model="formDatos.numeroDocumento" id="numeroDocumento" type="text" />
												<small v-if="erroresModalidad?.numeroDocumento" class="p-error" >{{ erroresModalidad.numeroDocumento }}</small>
											</div>
										</div>
										<div class="grid formgrid">
											<div class="field col-12 md:col-6">
												<label for="razonSocial">Razón Social</label>
												<InputText v-model="formDatos.razonSocial" id="correo" type="text" />
												<small v-if="erroresModalidad?.razonSocial" class="p-error" >{{ erroresModalidad.razonSocial }}</small>
											</div>
										</div>
										<div class="grid formgrid">
											<div class="field col-12 md:col-6">
												<label for="correoFacturacion">Correo de Facturación</label>
												<InputText v-model="formDatos.correoFacturacion" id="correo" type="text" />
												<small v-if="erroresModalidad?.correoFacturacion" class="p-error" >{{ erroresModalidad.correoFacturacion }}</small>
											</div>								
										</div>				
									</div>
								</div>					
							</div>				

							<!-- Paso 4 - Requisitos -->
							<div v-if="activeContent == 'documentos'" class="grid p-3 step-content documentos" :class="{'active-content': activeContent === 'documentos'}">
								
							</div>
							
							<!-- Paso 5 - Formulario de Inscripcion y Contrato -->
							<div v-if="activeContent == 'contrato'" class="grid p-3 step-content datos" :class="{'active-content': activeContent === 'contrato'}">
								<h5>Formulario de Inscripción / Contrato</h5>					
								<div class="grid">
									<div class="col-12 flex flex-column align-items-center justify-content-center">
										<div class="p-fluid">
											<Message severity="info" :closable="false" class="message-inscripcion">
												<p>
													<b>PASO 1:</b> Imprima el FORMULARIO DE INSCRIPCIÓN que acaba de llenar, presionando en el botón "Imprimir Formulario de Inscripción".
												</p>
												<p>
													<b>PASO 2:</b> Verifique sus datos en el FORMULARIO DE INSCRIPCIÓN impreso, en caso de que exista un error presione el botón "Anterior" hasta el <b>Paso 1</b> para corregir los errores y vuelva a imprimir el Formulario.
												</p>
												<p>
													<b>PASO 3:</b> Si sus datos en el Formulario de Inscripción impreso están correctos, presione el botón "Elaborar Contrato" para que pueda configurar su contrato y luego imprimirlo en tres ejemplares.
												</p>
												<p>
													<b>PASO 4:</b> Tanto su FORMULARIO DE INSCRIPCIÓN IMPRESO (una copia) como su CONTRATO IMPRESO (tres copias) deberán adjuntarse a los restantes documentos exigidos como requisitos para la admisión a los programas de posgrado.
												</p>
											</Message>
											<Divider layout="horizontal" align="center">
												<!-- <b>ó</b> -->
											</Divider>
											<div class="flex align-items-center justify-content-center">
												<div class="grid mt-2">
													<div class="col-12 md:col-12">
														<Button @click="verFormularioInscripcion()" class="p-button-secondary" label="Imprimir Formulario de Inscripción" />
													</div>
													<div class="col-12 md:col-12">
														<Button @click="verContrato()" class="p-button-primary" label="Elaborar Contrato" />
													</div>
												</div>
											</div>								
										</div>
									</div>
									
								</div>
							</div>

							<!-- Paso 6 - Enviar contrato -->
							<div v-if="activeContent == 'finalizar'" class="grid p-3 step-content finalizar" :class="{'active-content': activeContent === 'finalizar'}">
								<h5>Adjuntar Documentos y Finalizar</h5>	
								
									<h5>Requisitos del Programa</h5>
									<input type="file" ref="requisito" style="display: none" accept=".pdf, image/*" @change="leerRequisito"/>
									<div v-for="documento in documentosPrograma" class="grid formgrid" :key="documento.id">
										<div class="field col-6 md:col-3">
											{{ documento.nombre }}
										</div>
										<div class="field col-6 md:col-8">
											<template v-if="documento.documentacionInscripciones?.length > 0">
												<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRequisito(documento.documentacionInscripciones[0], 'A')" v-tooltip.top="'Adjuntar Documento'" />
												<Button v-if="documento.documentacionInscripciones[0].archivo" icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" @click="verDocumentoRequisito(documento.documentacionInscripciones[0].archivo)" v-tooltip.top="'Ver Documento'" />
												<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="eliminarDocumento(documento)" v-tooltip.top="'Eliminar Documento'"/> -->
											</template>
											<template v-else>
												<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRequisito(documento, 'C')" v-tooltip.top="'Adjuntar Documento'" />
											</template>
										</div>
									</div>
									
									<h5>Respaldos por Descuento {{ inscripcion.programaDescuento.nombre }}</h5>
									<input type="file" ref="respaldo" style="display: none" accept=".pdf, image/*" @change="leerRespaldo"/>
									<div v-for="(documento, index) in inscripcion.respaldo" class="grid formgrid" :key="index">
										<div class="field col-6 md:col-3">
											{{ documento.nombre }}
										</div>
										<div class="field col-6 md:col-8">
											<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRespaldo(documento)" v-tooltip.top="'Adjuntar Documento'" />
											<Button v-if="documento.archivo" icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" @click="verDocumentoRequisito(documento.archivo)" v-tooltip.top="'Ver Documento'" />
										</div>
									</div>

									<h5>Requisitos Generales</h5>	
									<input type="file" ref="requisitoGeneral" style="display: none" accept=".pdf, image/*" @change="leerRequisitoGeneral"/>
									<div v-for="documento in documentos" :key="documento.id" class="grid formgrid">
										<div class="field col-6 md:col-3">
											{{ documento.nombre }}
										</div>
										<div class="field col-6 md:col-8">
											<template v-if="documento.personaDocumentos?.length > 0">
												<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRequisitoGeneral(documento.personaDocumentos[0], 'A')" v-tooltip.top="'Adjuntar Documento'" />
												<Button v-if="documento.personaDocumentos[0].archivo" icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" @click="verDocumentoRequisito(documento.personaDocumentos[0].archivo)" v-tooltip.top="'Ver Documento'" />
												<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="eliminarDocumento(documento)" v-tooltip.top="'Eliminar Documento'"/> -->
											</template>
											<template v-else>
												<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRequisitoGeneral(documento, 'C')" v-tooltip.top="'Adjuntar Documento'" />
											</template>
										</div>
									</div>
									
									<!-- <input type="file" ref="respaldo" style="display: none" accept=".pdf, image/*" @change="leerRespaldo"/>
									<h5 v-if="formDatos.descuento?.datosVerificacion?.config?.documentos">Respaldos por Descuento</h5>	
									<div v-for="(documento, index) in formDatos.descuento?.datosVerificacion?.config?.documentos" :key="index" class="grid formgrid">
										<div class="field col-6 md:col-3">
											{{ documento }}
										</div>
										<div class="field col-6 md:col-8">
											<Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="adjuntarRespaldo(documento)" v-tooltip.top="'Adjuntar Documento'" />
											<Button v-if="existeRespaldo(documento)" icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" @click="verDocumentoRespaldo(documento)" v-tooltip.top="'Ver Documento'" />
										</div>
									</div> -->
								<div class="grid">
									<div class="col-12 flex flex-column align-items-center justify-content-center">
										<div class="p-fluid">
											<Message severity="info" :closable="false" class="message-inscripcion">
												<p>
													Para concluir el proceso de Inscripción: Imprima el CONTRATO, fírmelo, escanee y adjunte el documento en formato PDF. También puede llenar los datos del primer depósito o transferencia y adjuntar el comprobante.".
												</p>
											</Message>
											<div class="grid col-8">
												<div class="col-6">
													<InputSwitch v-model="formComprobante" inputId="formComprobante" class="mr-2"/>
													<label for="formComprobante">Habilitar Formulario del Comprobante</label>
												</div>
											</div>
											<div v-if="formComprobante" class="grid col-8">	
												<div class="col-12">
													<Message v-if="planPagos" severity="warn" :closable="false" class="message-inscripcion">
														<p v-if="formDatos.modalidadPago == 'Cuotas'">
															<strong>El pago inicial debe ser de: </strong>{{ planPagos?.costoPrimerPago }} Bs. por concepto de Matrícula {{ programa.nivelProgramaId == 1 ? 'más la primera cuota' : '' }}
														</p>
														<!-- <p v-else>
															<strong>Por concepto de Matrícula: </strong>{{ planPagos?.costoPrimerPago }} Bs.
														</p> -->
			
														<p v-if="formDatos.modalidadPago == 'Cuotas'">
															<strong>{{ programa.nivelProgramaId == 1 ? planPagos.nroCuotas-1 : planPagos.nroCuotas }} Cuotas restantes de: </strong>{{ planPagos?.costoColegiaturaCuotas  }} Bs.
														</p>
														<p v-else>
															<!-- <strong>Costo Matrícula: </strong>{{ planPagos?.costoMatricula }} Bs.<br/> -->
															<!-- <strong>Costo Colegiatura: </strong>{{ planPagos?.costoColegiaturaDescuento }} Bs.<br/> -->
															<strong>Monto de pago: </strong>{{ (planPagos?.costoMatricula + planPagos?.costoColegiaturaDescuento)  }} Bs.
														</p>
													</Message>
												</div>										
												<div class="col-6">
													<div class="field">
														<label for="numeroComprobante">Nro. Comprobante</label>
														<InputText v-model="formPago.numeroComprobante" :disabled="pagoEdit" id="numeroComprobante" type="text"/>
														<small v-if="erroresPago?.numeroComprobante" class="p-error" >{{ erroresPago.numeroComprobante }}</small>
													</div>
												</div>
												<div class="col-6">
													<div class="field">
														<label for="tipo">Tipo de Transacción</label>
														<!-- <Dropdown v-model="formPago.tipo" :disabled="pagoEdit" :options="tiposTransaccion" placeholder="- Seleccionar -"></Dropdown> -->
														<VueSelect v-model="formPago.tipo" :disabled="pagoEdit" :options="tiposTransaccion" placeholder="- Seleccionar -"></VueSelect>
														<small v-if="erroresPago?.tipo" class="p-error" >{{ erroresPago.tipo }}</small>
													</div>
												</div>
												<div class="col-6">
													<div class="field">
														<label for="nombreDepositante">Nombre del Depositante</label>
														<InputText v-model="formPago.nombreDepositante" :disabled="pagoEdit" id="nombreDepositante" type="text"/>
														<small v-if="erroresPago?.nombreDepositante" class="p-error" >{{ erroresPago.nombreDepositante }}</small>
													</div>
												</div>
												<div class="col-6">
													<div class="field">
														<label for="monto">Monto Depositado/Transferido</label>
														<InputNumber v-model="formPago.monto" id="monto" :disabled="pagoEdit" :minFractionDigits="2" :maxFractionDigits="2"/>
														<small v-if="erroresPago?.monto" class="p-error" >{{ erroresPago.monto }}</small>
													</div>
												</div>
												<div class="col-6">
													<div class="field">
														<label for="fechaDeposito">Fecha de la Transacción</label>
														<Calendar v-model="formPago.fechaDeposito" dateFormat="dd/mm/yy" :maxDate="fechaHoy" :disabled="pagoEdit" :showIcon="true" :showButtonBar="true"/>
														<small v-if="erroresPago?.fechaDeposito" class="p-error" >{{ erroresPago.fechaDeposito }}</small>
													</div>
												</div>
												<div class="col-6">
													<div class="field">
														<label for="comprobante">Archivo Comprobante</label><br />
														<input type="file" ref="comprobante" style="display: none" accept=".pdf, image/*" @change="leerComprobante"/>
														<Button @click="adjuntarComprobante()" :disabled="pagoEdit" class="p-button-primary w-auto" label="Adjuntar" />
														<Button v-if="comprobanteFile" icon="pi pi-eye" class="p-button-rounded p-button-warning ml-2" @click="verDocumentoComprobante" v-tooltip.top="'Ver Documento'" />
														<br/>
														<small v-if="erroresPago?.comprobante" class="p-error" >{{ erroresPago.comprobante }}</small>

													</div>
												</div>
											</div>									
											<Divider layout="horizontal" align="center">
												<!-- <b>ó</b> -->
											</Divider>
											<div class="flex align-items-center justify-content-center">
												<div class="grid mt-2">
													<div class="col-12 md:col-12 flex">
														<input type="file" ref="contrato" style="display: none" accept=".pdf" @change="leerContrato"/>
														<Button @click="adjuntarContrato()" class="p-button-primary w-auto" label="Adjuntar Contrato" />
														<Button v-if="contratoFile" icon="pi pi-eye" class="p-button-rounded p-button-warning ml-2" @click="verDocumentoContrato" v-tooltip.top="'Ver Documento'" />
													</div>
												</div>
											</div>									
										</div>
									</div>
								</div>
							</div>

							<!-- botones de navegacion -->
							<div class="flex justify-content-between flex-wrap mt-5 mb-6">
								<div>
									<Button v-if="activeContent != 'datos'" type="button" class="p-button-outlined" label="Anterior" @click="clickBack(activeContent)" icon="pi pi-arrow-left"/>
								</div>
								<div>
									<Button v-if="activeContent == 'finalizar'" class="w-max" :loading="isLoading" label="Finalizar" @click="clickNext(activeContent)" icon="pi pi-check" iconPos="right"/>
									<Button v-else class="w-min" label="Siguiente" :loading="isLoading" @click="clickNext(activeContent)" icon="pi pi-arrow-right" iconPos="right"/>    
								</div>
							</div>
							<div v-if="activeContent == 'datos' || activeContent == 'finalizar'" class="flex justify-content-center mt-5">
								<router-link to="/">
									<Button label="Ir al Panel de Cursante" class="p-button-outlined"/>
								</router-link>
							</div>
						</div>
					</div>		
					<Toast />
					<ConfirmDialog group="dialog" />	
					<VisorDocumento
						:visibleModal="showVisor"
						:isBase64="isBase64"
						:dataFile="dataFile"
						@close="cerrarVisor"
					/>
				</div>
			</div>
		</div>
	
		<div v-else class="exception-body notfound">
			<div class="exception-content">
				<div class="grid mt-2 p-8">
					<div class="col-12">
						<p class="text-center"><img class="img-thumbnail" style="height: 6rem;" src="/images/logo-cepi-web-dark.png" alt="logo"></p>
						<h2 class="title uppercase font-bold text-primary text-center">Formulario de Inscripción</h2>
					</div>
				</div>
				<div class="exception-text">
					<div class="notfound-box">
						<span>404</span>
					</div>
					<span class="notfound-text"> El Programa no está disponible</span>
				</div>
			</div>
			<div class="exception-panel">
				<div class="exception-image">
					<img src="/layout/images/exception/icon-error.png" alt="avalon-vue"/>
				</div>
				<div class="exception-panel-content">
					<div class="information-text">
						<h3>El formulario de inscripción para el programa no está disponible...</h3>
						<p>Para acceder a su cuenta regrese al panel de cursante</p>
					</div>
					<router-link to="/">
						<Button label="Ir al Panel de Cursante" />
					</router-link>
				</div>
			</div>
		</div>	
	</div>
</template>
<script>
	import { useAuthStore } from '../../stores/authStore';
	import { 
		ReporteService,
		ProgramaService,
		InscripcionService,
		ProgramaDescuentoService,
		RequisitoService,
		AuthService,
		EstudianteService,
		ArchivoService,
		PagoService,
		PersonaDocumentoService,
		DocumentacionInscripcionService,
	} from '../../service';
	import { 
		jsonDataContrato,
		jsonDataFormInscripcion,
	} from '../../helpers/programaUtil';
	import { 
		getDateFromDB,
		addDateDays,
	} from '../../helpers/date.util';
	import { datosSchema } from './validations/datosValidate';
	import { registroSchema } from './validations/registroValidate';
	import { modalidadSchema } from './validations/modalidadValidate';
	import { pagoSchema } from './validations/pagoValidate';

	import VisorDocumento from '../reportes/modals/VisorDocumento';

	const ordinales = require('ordinales-js');

	export default {
		components: {
			VisorDocumento,
        },
		setup() {
			const authStore = useAuthStore();			
			return {
				authStore
			}
		},
		data() {
			return {
				edicion: false,
				load: false,
				isLoading: false,
				errorStep: false,
				activeContent: 'datos',
				activeIndex: 0,
				activeTab: 'datos',

				fechaHoy: new Date(),
				programa: null,
				programaId: null,
				version: null,
				estudianteId: null,

				inscripcionId: null,
				inscripcion: null,

				tokenGoogle: null,
				sesionGoogle: false,

				formDatos: {
					tipoParticipante: null,
					documento: null,
					complemento: null,
					expedido: null,
					nombres: null,
					apellidoPaterno: null,
					apellidoMaterno: null,
					sexo: null,
					paisOrigen: null,
					departamentoOrigen: null,
					fechaNacimiento: null,
					estadoCivil: null,
					paisResidencia: null,
					departamentoResidencia: null,
					municipioResidencia: null,
					direccion: null,
					telefono: null,
					celular: null,
					correo: null,
					universidad: null,
					areaEstudio: null,
					gradoObtenido: null,
					fechaDiploma: null,
					fechaTitulo: null,

					modalidadPago: 'Cuotas',
					
					descuento: null,
					cuotas: 1,
					diaLimite: 1,

					tipoDocumento: null,
					complementoFacturacion: null,
					numeroDocumento: null,
					razonSocial: null,
					correoFacturacion: null,

					password: null,
					confirmPassword: null,
				},
				existeCursante: false,

				configAnterior:{
					destinatarioId: null,
					contrato: null,
					contratoFile: null,
					modalidad: null,
					programaDescuentoId: null,
					diaPago: null,
					cuotas: null,
				},

				planPagos: null,
				
				erroresDatos: null,
				erroresRegistro: null,
				erroresModalidad: null,

				tiposParticipante: [],
				estadosCivil: [],
				sexoOpciones: [
					'Masculino',
					'Femenino'
				],
				paises: [],
				departamentos: [],
				municipios: [],
				
				universidades: [],
				universidadesFiltrado:[],

				areasEstudio: [],

				gradosAcademicos: [],
				gradosAcademicosFiltrado: [],

				// Items navegacion
				items: [
					{
						name: 'datos',
						label: 'Datos Generales',
						route: '/'
					},
					{
						name: 'modalidad',
						label: 'Modalidad de Pago / Datos Facturación',
						route: '/'
					},					
					{
						name: 'contrato',
						label: 'Formulario Inscripción y Contrato',
						route: '/'
					},
					{
						name: 'finalizar',
						label: 'Adjuntar documentos y Finalizar',
						route: '/'
					},
				],

				dataFile: null,
				isBase64: true,
				showVisor: false,

				tiposDocumento: [],

				respaldosDescuentos: [],
				respaldoFile: null,
				objetoRespaldo: null,

				requisitosDocumentos: [],

				requisitoFile: null,

				nombreRequisito: null,
				requisitoId: null,	

				tipoRequisito: null,
				tipoRequisitoVerificacion: 'archivo', 
				
				contratoFile: null,
				comprobanteFile: null,

				maximoCuotas: null,
				maximoDiaLimite: null,
				
				requisitos: null,

				documentos: null,
				documentosPrograma: null,

				objetoDocumento: null,
				accion: null,

				tiposTransaccion: [
					'Depósito',
					'Transferencia'
				],
				formComprobante: false,
				formPago: {
					numeroComprobante: null,
					tipo: null,
					nombreDepositante: null,
					monto: null,
					fechaDeposito: new Date(),
				},
				pagoEdit: false,
				pago: null,
				erroresPago: null,
				descuentos: null,
				descuentosOriginal: null,
			}
		},
		async mounted() {
			this.load = false;
			if(this.$route.params.id && !this.$route.params.inscripcionId){
				this.programaId = this.$route.params.id;
				
				await this.getPrograma(this.programaId);
				await this.getPaises();
				await this.getDepartamentos();
				await this.getEstadosCivil();

				await this.getUniversidades();
				await this.getAreasEstudio();
				await this.getGradosAcademicos();
				await this.getTiposDocumento();
			}

			if(this.$route.params.id && this.$route.params.inscripcionId){
				this.edicion = true;
				this.programaId = this.$route.params.id;
				this.inscripcionId = this.$route.params.inscripcionId;

				await this.getPrograma(this.programaId);
				await this.getPaises();
				await this.getDepartamentos();
				await this.getEstadosCivil();

				await this.getUniversidades();
				await this.getAreasEstudio();
				await this.getGradosAcademicos();
				await this.getTiposDocumento();
				
				if(this.authStore.isTokenValid()){
					await this.getInscripcion(this.inscripcionId);
				}else{
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: `Debe iniciar sesión para editar una inscripción.`, life: 3000});
					this.$router.push('/login');	
				}
			}
			this.load = true;
			// await this.validarDatos();

			//obtener los datos del estudiante si tiene sesión
			if(this.authStore.isTokenValid()){
				this.formDatos.documento = this.authStore.getUser?.ci;
				await this.buscarPersona();
			}
		},
		watch: {
			$route: function(val){
				console.log(val)
			},
		},
		created() {									
			
		},
		methods: {
			logout(){
				this.authStore.logoutFormInscripcion();
			},
			isActive(item) {
				return item.name == this.activeContent ? true : false; 
			},
			activeItem(){
				this.activeIndex = this.items.findIndex(objeto => objeto.name == this.activeContent);				
			},
			async clickNext(step) {
				console.log(step);
				switch (step) {
					case 'datos':
						await this.validarDatos();
						
						if(!this.errorStep){
							if(this.authStore.isTokenValid()){
								await this.iniciarInscripcion();
							}else{
								await this.loginRegister();
							}
							
							if(!this.errorStep) {
								//obtener documentos
								await this.getDescuentos();
								if(this.formDatos.modalidadPago){
									await this.getPlanPagos();
									// await this.cambiarModalidadPago();
								}
							}

							!this.errorStep ? this.activeContent = 'modalidad' : this.activeContent = step;
						}

                        break;			
					// case 'registro':
					// 	await this.loginRegister();
					// 	!this.errorStep ? this.activeContent = 'modalidad' : this.activeContent = step;
					// 	if(!this.errorStep) {
					// 		//obtener documentos
					// 		await this.getDescuentos();
					// 		if(this.formDatos.modalidadPago){
					// 			await this.getPlanPagos();
					// 		}
					// 	}
					// 	break;
					case 'modalidad':
						await this.registrarModalidadFacturacion();
						!this.errorStep ? this.activeContent = 'contrato' : this.activeContent = step;
						// this.activeContent = 'documentos';
						if(!this.errorStep) {
							// await this.getDocumentosPersona();
							// await this.getRequisitos();

							await this.getRequisitosGenerales();
							await this.getRequisitosPrograma();
						}
						break;					
					case 'contrato':
						// if(this.existeCambiosConfigContrato()){
						// 	this.contratoFile = null;
						// 	this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Genere y descargue el Contrato, porque se hicieron cambios en la modalidad de pago.', life: 5000});
						// }else{
						// 	this.contratoFile = this.configAnterior.contratoFile;
						// }	
						await this.getPagoInscripcion();
						this.activeContent = 'finalizar';					
						
						break;
					case 'finalizar':
						// generar contrato
						await this.finalizarInscripcion();
					break;
				}
				this.activeItem();
			},
			clickBack(step){
				console.log(step)
				switch (step) {
					case 'datos':						
						break;
					// case 'registro':
					// 	this.activeContent = 'datos'
					// 	break;			
					case 'modalidad':
						this.activeContent = 'datos'
						if(this.isTokenValid() && this.inscripcionId){
							this.activeContent = 'datos';
						}
						break;			
					case 'contrato':
						this.activeContent = 'modalidad'
						break;
					case 'finalizar':
						this.activeContent = 'contrato'
						break;
				}
				this.activeItem();
			},

			async getPaises(){
				this.paises = await InscripcionService.paises();
			},
			async getDepartamentos(){
				this.departamentos = await InscripcionService.departamentos();
			},
			async getMunicipios(){
				this.municipios = await InscripcionService.municipios(this.formDatos.departamentoResidencia.id);
			},
			async getEstadosCivil(){
				this.estadosCivil = await InscripcionService.estadosCivil();
			},
			async getUniversidades(){
				this.universidades = await InscripcionService.universidades();
			},
			async getAreasEstudio(){
				this.areasEstudio = await InscripcionService.areasEstudio();
			},
			async getGradosAcademicos(){
				this.gradosAcademicos = await InscripcionService.gradosAcademicos();
			},
			async getTiposDocumento(){
				this.tiposDocumento = await InscripcionService.tiposDocumento();
			},
			async getPagoInscripcion(){
				try {
					const response = await PagoService.getPagoInscripcion(this.inscripcionId);
					console.log(response)
					if(response){
						let comprobante = response.data.comprobante;
						this.pago = response.data;
						this.pagoEdit = response.data.estadoId == 7 ? false : true;

						this.formComprobante = true;
						this.formPago.numeroComprobante = comprobante.nroComprobante;
						this.formPago.tipo = comprobante.tipo;
						this.formPago.nombreDepositante = comprobante.nombreDepositante;
						this.formPago.monto = parseFloat(comprobante.monto);
						this.formPago.fechaDeposito = comprobante.fechaDeposito;
						
						// this.comprobanteFile archivo
						try {
							const response = await ArchivoService.getArchivoBase64(comprobante.archivo);
						
							if(response.data?.transaccion){
								this.comprobanteFile = response.data.datos;
								this.isBase64 = true;													
							}else{
								this.comprobanteFile = null;
							}
						} catch (error) {
							this.comprobanteFile = null;
						}
					}
				} catch (error) {
					console.error(error);
					this.pagoEdit = false;
				}
			},
			async getPrograma(id){
				try {
					const response = await ProgramaService.getPrograma(id);
					// console.log(response);
					this.programa = response.data;
					this.version = ordinales.toOrdinal(this.programa.version, 'f').replace(/^\w/, (letra) => letra.toUpperCase())
					this.maximoCuotas = this.programa.nivelProgramaId == 1 ? response.data.numeroCuotas-1: response.data.numeroCuotas;
					this.maximoDiaLimite = response.data.diaLimitePago;

					this.tiposParticipante = this.programa.programaDestinatarios.map((item) => ({
						id: item.id,
						destinatarioId: item.destinatario.id,
						nombre: item.destinatario.nombre,
						costoTotal: item.costoTotal,
						costoMatricula: item.costoMatricula,
						costoColegiatura: item.costoColegiatura,
						costoDefensaTutoria: item.costoDefensaTutoria
					}));
					if(this.tiposParticipante.length == 1)
						this.formDatos.tipoParticipante = this.tiposParticipante[0];
					
				} catch (error) {
					console.log(error);
				}
			},

			async buscarPersona(){
				if (this.formDatos.documento?.length >= 7){
					this.existeCursante = false;
					try {						
						const response = await InscripcionService.buscarPersona(this.formDatos.documento);
						// console.log(response);
						if(response?.numeroIdentificacion){
							// this.formDatos.tipoParticipante = response.tipoParticipante;
							// this.formDatos.documento = response.documento;
							
							this.formDatos.complemento = response.complemento;
							this.formDatos.expedido = this.departamentos.find(objeto => objeto.abreviacion === response.expedido);
							this.formDatos.nombres = response.nombres;
							this.formDatos.apellidoPaterno = response.apellidoPaterno;
							this.formDatos.apellidoMaterno = response.apellidoMaterno;
							this.formDatos.sexo = response.sexo;
							this.formDatos.paisOrigen = response.paisOrigen;
							this.formDatos.departamentoOrigen = response.departamentoOrigen;
							this.formDatos.fechaNacimiento = response.fechaNacimiento ? addDateDays(getDateFromDB(response.fechaNacimiento), 0) : new Date();
							this.formDatos.estadoCivil = response.estadoCivil;

							this.formDatos.paisResidencia = response.pais;
							this.formDatos.departamentoResidencia = response?.municipio?.departamento;
							this.formDatos.municipioResidencia = response.municipio;
							this.formDatos.direccion = response.direccion; 
							this.formDatos.telefono = response.telefono;
							this.formDatos.celular = response.celular;

							this.formDatos.correo = response.estudiante?.email;

							if(response.datosFacturacion){
								this.formDatos.tipoDocumento = response.datosFacturacion.tipoDocumento;
								// this.formDatos.complemento = response.datosFacturacion.complemento;
								this.formDatos.numeroDocumento = response.datosFacturacion.numDocumento;
								this.formDatos.razonSocial = response.datosFacturacion.razonSocial;
								this.formDatos.correoFacturacion = response.datosFacturacion.correoFacturacion;
							}							

							if(this.formDatos.departamentoResidencia)
								this.getMunicipios();

							if(response.estudiante?.id){
								this.existeCursante = true;
								const responseIns = await InscripcionService.getInscripcionEstudiante(response.estudiante.id);
								
								if(responseIns){
									this.formDatos.universidad = responseIns.universidad;
									this.formDatos.areaEstudio = responseIns.area;
									this.formDatos.gradoObtenido = responseIns.gradoAcademico;
									this.formDatos.fechaDiploma = responseIns.fechaDiploma ? addDateDays(getDateFromDB(responseIns.fechaDiploma), 0) : new Date();
									this.formDatos.fechaTitulo = responseIns.fechaTitulo ? addDateDays(getDateFromDB(responseIns.fechaTitulo), 0) : new Date();
								}								
							}								

							// await this.validarDatos();
						}else{
							this.existeCursante = false;
						}					

					} catch (error) {
						// console.log(error);
						this.existeCursante = false;
					}
				}					
			},

			async getInscripcion(id){
				try {
					const response = await InscripcionService.getInscripcion(id);
					this.inscripcion = response;

					this.formDatos.tipoParticipante = response.tipoParticipante;
					this.formDatos.documento = response.estudiante.persona.numeroIdentificacion;
					// this.formDatos.complemento = response.estudiante.persona.complemento;
					this.formDatos.expedido = this.departamentos.find(objeto => objeto.abreviacion === response.estudiante.persona.expedido);
					this.formDatos.nombres = response.estudiante.persona.nombres;
					this.formDatos.apellidoPaterno = response.estudiante.persona.apellidoPaterno;
					this.formDatos.apellidoMaterno = response.estudiante.persona.apellidoMaterno;
					this.formDatos.sexo = response.estudiante.persona.sexo;
					this.formDatos.paisOrigen = response.estudiante.persona.paisOrigen;
					this.formDatos.departamentoOrigen = response.estudiante.persona.departamentoOrigen;
					this.formDatos.fechaNacimiento = addDateDays(getDateFromDB(response.estudiante.persona.fechaNacimiento), 0);
					this.formDatos.estadoCivil = response.estudiante.persona.estadoCivil;

					this.formDatos.paisResidencia = response.estudiante.persona.pais;
					this.formDatos.departamentoResidencia = response.estudiante.persona.municipio?.departamento;
					this.formDatos.municipioResidencia = response.estudiante.persona.municipio;
					this.formDatos.direccion = response.estudiante.persona.direccion; 
					this.formDatos.telefono = response.estudiante.persona.telefono;
					this.formDatos.celular = response.estudiante.persona.celular;

					this.estudianteId = response.estudiante?.id;
					this.formDatos.correo = response.estudiante?.email;

					if(response.estudiante.persona.datosFacturacion){
						this.formDatos.tipoDocumento = response.estudiante.persona.datosFacturacion.tipoDocumento;
						// this.formDatos.complemento = response.datosFacturacion.complemento;
						this.formDatos.numeroDocumento = response.estudiante.persona.datosFacturacion.numDocumento;
						this.formDatos.razonSocial = response.estudiante.persona.datosFacturacion.razonSocial;
						this.formDatos.correoFacturacion = response.estudiante.persona.datosFacturacion.correoFacturacion;
					}							

					if(this.formDatos.departamentoResidencia)
						this.getMunicipios();
										
					this.formDatos.tipoParticipante = this.tiposParticipante.find(item => item.destinatarioId == response.destinatarioId);
					this.formDatos.universidad = response.universidad;
					this.formDatos.areaEstudio = response.area;
					this.formDatos.gradoObtenido = response.gradoAcademico;
					this.formDatos.fechaDiploma = addDateDays(getDateFromDB(response.fechaDiploma), 0);
					this.formDatos.fechaTitulo = addDateDays(getDateFromDB(response.fechaTitulo), 0);

					this.formDatos.modalidadPago = response.modalidadPago;
					if(response.numeroCuotas)
						this.formDatos.cuotas = this.programa.nivelProgramaId == 1 ? response.numeroCuotas-1 : response.numeroCuotas;
					else
						this.formDatos.cuotas = 1;

					this.formDatos.diaLimite = response.diaPago;
					this.respaldosDescuentos = response.respaldo;

					// recuperar contrato cargado
					if(response.contrato){
						const resp = await ArchivoService.getArchivoBase64(response.contrato);
						
						if(resp.data?.transaccion){
							this.contratoFile = resp.data.datos;
							this.isBase64 = true;												
						}else{
							this.contratoFile = null;
							this.isBase64 = false;
						}
					}
					// recuperar configuración de modalidad de pago
					this.configAnterior.destinatarioId = response.destinatarioId;
					this.configAnterior.contrato = response.contrato;
					this.configAnterior.contratoFile = this.contratoFile;
					this.configAnterior.modalidad = response.modalidadPago;
					this.configAnterior.programaDescuentoId = response.programaDescuentoId;
					this.configAnterior.diaPago = response.diaPago;
					if(response.numeroCuotas)
						this.configAnterior.cuotas = this.programa.nivelProgramaId == 1 ? response.numeroCuotas-1 : response.numeroCuotas;
					else
						this.configAnterior.cuotas = response.numeroCuotas;

				} catch (error) {
					console.log(error);
				}
			},

			async getUpdateInscripcion(id){
				try {
					const response = await InscripcionService.getInscripcion(id);
					this.inscripcion = response;
				} catch (error) {
					console.log(error);
				}
			},

			async getDescuentos(){
				// this.descuentos = await DescuentoService.getDescuentos(this.programaId, this.formDatos.tipoParticipante.destinatarioId);
				this.descuentos = await ProgramaDescuentoService.getDescuentos(this.formDatos.tipoParticipante.id); // enviar id del programadestinatario
				this.descuentos = this.descuentos.map((item) => ({
					...item,
					datosVerificacion: item.descuento.datosVerificacion,
					descripcion: `${item.nombre} - ${item.porcentaje}%`
				}));
				this.descuentosOriginal = this.descuentos;
				if(!this.inscripcion.programaDescuentoId){
					this.formDatos.descuento = this.descuentos.find(item => item.porcentaje == 0);
				}else{
					this.formDatos.descuento = this.descuentos.find(item => item.id == this.inscripcion.programaDescuentoId);
				}
			},

			async cambiarModalidadPago(){
				if(this.formDatos.modalidadPago == 'Cuotas'){
					this.descuentos = this.descuentosOriginal.filter(item => item.nombre.toLowerCase().indexOf('contado') == -1);
					this.maximoCuotas = this.programa.nivelProgramaId == 1 ? this.programa.numeroCuotas-1 : this.programa.numeroCuotas;
					this.formDatos.descuento = this.descuentos[0];
					this.formDatos.cuotas = this.maximoCuotas;	
				}else{
					this.descuentos = this.descuentosOriginal;
					this.maximoCuotas = 1;	
					this.formDatos.cuotas = 1;
				}

				await this.getPlanPagos()
			},
			validarCuotas(){
				if(this.formDatos.cuotas > this.maximoCuotas){
					this.formDatos.cuotas = this.maximoCuotas;
				}
			},
			existeCambiosConfigContrato(){
				return  this.configAnterior.destinatarioId != this.formDatos.tipoParticipante.destinatarioId ||  
						this.configAnterior.modalidad != this.formDatos.modalidadPago || 
						this.configAnterior.programaDescuentoId != this.formDatos.descuento.id || 
						this.configAnterior.diaPago != this.formDatos.diaLimite || 
						this.configAnterior.cuotas != this.formDatos.cuotas
			},
			async getPlanPagos(){
				
				try {
					const data = {
						descuento: this.formDatos.descuento?.porcentaje,
						cuotas: this.programa.nivelProgramaId == 1 ? this.formDatos.cuotas+1 : this.formDatos.cuotas,
						programaId: parseInt(this.programaId),
						destinatarioId: this.formDatos.tipoParticipante.destinatarioId,
						modalidadPago: this.formDatos.modalidadPago
					};
					const response = await InscripcionService.getPlanPagos(data);
					this.planPagos = response;
				} catch (error) {
					console.log(error)
				}
			},

			async getDocumentosPersona(){
				const response = await PersonaDocumentoService.documentosPersona(this.inscripcion.estudiante.persona.id);
				this.requisitosDocumentos = response;
			},

			async getRequisitos(){
				this.requisitos = await RequisitoService.getRequisitos(this.formDatos.tipoParticipante.destinatarioId);
			},

			async getRequisitosGenerales(){
				const response = await RequisitoService.requisitosGenerales(this.inscripcion.destinatarioId);
                this.documentos = response;
			},

			async getRequisitosPrograma(){
				this.documentosPrograma = await RequisitoService.listaInscripcion(this.inscripcionId);
			},

			async validarDatos(){
				await datosSchema
						.validate(this.formDatos, { abortEarly: false })
						.then(() => {
							this.errorStep = false;
							this.erroresDatos = {};
						})
						.catch((errors) => {
							// Manejar los errores de validación
							const formattedErrors = {};
							errors.inner.forEach((error) => {
								formattedErrors[error.path] = error.message;
							});

							this.erroresDatos = formattedErrors;
							this.errorStep = true;
						});

			},

			async seleccionarDescuento(){
				this.respaldosDescuentos = [];
				await this.getPlanPagos();
			},

			async loginRegister(){
				let errorValidacion = false;
				const dataValidate = {
					correo: this.formDatos.correo.trim().toLowerCase(),
					sesionGoogle: this.sesionGoogle,
					password: this.formDatos.password,
					existeCursante: this.existeCursante,
					confirmPassword: this.formDatos.confirmPassword
				};			
				await registroSchema.validate(dataValidate, { abortEarly: false })
					.then(() => {
						errorValidacion = false;
						this.erroresRegistro = null;
						this.errorStep = false;
					})
					.catch((errors) => {
						// Manejar los errores de validación
						console.error('Errores de validación:', errors.errors);
						const formattedErrors = {};
						errors.inner.forEach((error) => {
							formattedErrors[error.path] = error.message;
						});

						this.erroresRegistro = formattedErrors;
						this.errorStep = true;
						errorValidacion = true;
					});

				if(!errorValidacion){
					try {
						const response = await AuthService.login(this.formDatos.correo.trim().toLowerCase(), this.formDatos.password.trim());
						// console.log(response.data);
						let data = {
							email: this.formDatos.correo.trim().toLowerCase(),
							password: this.formDatos.password.trim(),
							nombres: this.formDatos.nombres,
							apellidoPaterno: this.formDatos.apellidoPaterno,
							apellidoMaterno: this.formDatos.apellidoMaterno,
							numeroIdentificacion: this.formDatos.documento,
							direccion: this.formDatos.direccion,
							telefono: this.formDatos.telefono,
							celular: this.formDatos.celular,
							expedido: this.formDatos.expedido.abreviacion,
							sexo: this.formDatos.sexo,
							paisOrigenId: this.formDatos.paisOrigen.id,
							departamentoOrigenId: this.formDatos.departamentoOrigen.id,
							fechaNacimiento: this.formDatos.fechaNacimiento,
							paisId: this.formDatos.paisResidencia.id,
							municipioId: this.formDatos.municipioResidencia.id,
							estadoCivilId: this.formDatos.estadoCivil.id
						};

						if(response.data?.transaccion == false){
							if(response.data?.mensaje.indexOf('cuenta no existe') > 0){
								// llamar servicio para registro de estudiante
								const responseRegistro = await AuthService.registrar(data);
								if(responseRegistro.data?.transaccion == true){
									this.errorStep = false;
									this.estudianteId = responseRegistro.data.datos.usuario.id;
								}else{
									this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${responseRegistro.data.mensaje}. `, life: 3000});
									this.errorStep = true;
								}
							}else{
								// mensaje que los datos de acceso son incorrectos
								this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${response.data.mensaje}. Escriba la contraseña correcta.`, life: 3000});
								this.errorStep = true;
							}
						}else{
							this.errorStep = false;
							this.estudianteId = response.data.datos.usuario.id;

							//actualizar datos del Cursante
							delete data.password;
							delete data.numeroIdentificacion;
							// delete data.expedido;
							// delete data.sexo;
							// delete data.estadoCivilId;

							try {
								const respEstudiante = await EstudianteService.actualizar(data);
								if(respEstudiante.data?.transaccion == true){
									this.$toast.add({severity:'success', summary: 'Mensaje', detail: `${respEstudiante.data.mensaje}.`, life: 3000});
								}else{
									this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${respEstudiante.data.mensaje}.`, life: 3000});
								}
							} catch (error) {
								console.log(error);
							}
						}

						// Registrar inscripcion inicial
						if(this.errorStep == false){
							await this.registrarInscripcion();
						}

					} catch (error) {
						console.log(error);
						this.errorStep = true;
					}
				}
				
			},

			async iniciarInscripcion(){
				let data = {
					email: this.formDatos.correo.trim().toLowerCase(),
					nombres: this.formDatos.nombres,
					apellidoPaterno: this.formDatos.apellidoPaterno,
					apellidoMaterno: this.formDatos.apellidoMaterno,
					direccion: this.formDatos.direccion,
					telefono: this.formDatos.telefono,
					celular: this.formDatos.celular,
					paisOrigenId: this.formDatos.paisOrigen.id,
					departamentoOrigenId: this.formDatos.departamentoOrigen.id,
					fechaNacimiento: this.formDatos.fechaNacimiento,
					paisId: this.formDatos.paisResidencia.id,
					municipioId: this.formDatos.municipioResidencia.id,
				};

				this.errorStep = false;
				this.estudianteId = this.authStore.getUser?.id; // recuperar el id de estudiante de la session
				
				try {
					const respEstudiante = await EstudianteService.actualizar(data);
					if(respEstudiante.data?.transaccion == true){
						this.$toast.add({severity:'success', summary: 'Mensaje', detail: `${respEstudiante.data.mensaje}.`, life: 3000});

					}else{
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${respEstudiante.data.mensaje}.`, life: 3000});
					}
				} catch (error) {
					console.log(error);
				}

				if(this.estudianteId){
					await this.registrarInscripcion();
				}
			},

			async registrarInscripcion(){
				const dataIns = {
					programaId: parseInt(this.programaId),
					estudianteId: this.estudianteId,
					areaId: this.formDatos.areaEstudio.id,
					universidadId: this.formDatos.universidad.id,
					gradoAcademicoId: this.formDatos.gradoObtenido.id,
					destinatarioId: this.formDatos.tipoParticipante.destinatarioId,
					fechaDiploma: this.formDatos.fechaDiploma,
					fechaTitulo: this.formDatos.fechaTitulo
				};
				
				try {
					const response = await InscripcionService.registrar(dataIns);
					// console.log(response);
					if(response?.transaccion == true){
						this.errorStep = false;
						this.inscripcionId = response.datos.id;
						this.inscripcion = response.datos;

						// actualizar datos de la inscripción
						const respInsc = await InscripcionService.actualizarPrimerPaso(dataIns, this.inscripcionId);
						if(respInsc?.transaccion == true){
							this.inscripcion = respInsc.datos;
						}

						this.estudianteId = this.inscripcion.estudiante?.id;

						if(this.inscripcion.estudiante.persona.datosFacturacion){
							this.formDatos.tipoDocumento = this.inscripcion.estudiante.persona.datosFacturacion.tipoDocumento;
							this.formDatos.numeroDocumento = this.inscripcion.estudiante.persona.datosFacturacion.numDocumento;
							this.formDatos.razonSocial = this.inscripcion.estudiante.persona.datosFacturacion.razonSocial;
							this.formDatos.correoFacturacion = this.inscripcion.estudiante.persona.datosFacturacion.correoFacturacion;
						}

						if(this.inscripcion.modalidadPago){
							this.formDatos.modalidadPago = this.inscripcion.modalidadPago;
							this.formDatos.cuotas = this.programa.nivelProgramaId == 1 ? this.inscripcion.numeroCuotas-1 : this.inscripcion.numeroCuotas;
							this.formDatos.diaLimite = this.inscripcion.diaPago ?? 1;
							
							// this.getPlanPagos();
						}

						this.respaldosDescuentos = this.inscripcion.respaldo;
						
						// recuperar contrato cargado
						if(this.inscripcion?.contrato){
							const resp = await ArchivoService.getArchivoBase64(this.inscripcion.contrato);
							
							if(resp.data?.transaccion){
								this.contratoFile = resp.data.datos;
								this.isBase64 = true;												
							}else{
								this.contratoFile = null;
								this.isBase64 = false;
							}
						}

						// recuperar configuración de modalidad de pago
						this.configAnterior.destinatarioId = this.inscripcion.destinatarioId;
						this.configAnterior.contrato = this.inscripcion.contrato;
						this.configAnterior.contratoFile = this.contratoFile;
						this.configAnterior.modalidad = this.inscripcion.modalidadPago;
						this.configAnterior.programaDescuentoId = this.inscripcion.programaDescuentoId;
						this.configAnterior.diaPago = this.inscripcion.diaPago;
						if(this.inscripcion.numeroCuotas)
							this.configAnterior.cuotas = this.programa.nivelProgramaId == 1 ? this.inscripcion.numeroCuotas-1 : this.inscripcion.numeroCuotas;
						else
							this.configAnterior.cuotas = this.inscripcion.numeroCuotas;

					}else{
						this.errorStep = true;
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: `${response.mensaje}`, life: 3000});
					}
				} catch (error) {
					console.log(error);
				}
			},

			async registrarModalidadFacturacion(){
				let errorValidacion = false;
				const dataValidate = {
					descuento: this.formDatos.descuento,
					cuotas: this.formDatos.cuotas,
					diaLimite: this.formDatos.diaLimite,

					tipoDocumento: this.formDatos.tipoDocumento,
					numeroDocumento: this.formDatos.numeroDocumento,
					razonSocial: this.formDatos.razonSocial,
					existeCorreo: this.formDatos.correoFacturacion ? true : false,
					correoFacturacion: this.formDatos.correoFacturacion, 
				};
				await modalidadSchema.validate(dataValidate, { abortEarly: false })
					.then(() => {
						errorValidacion = false;
						this.erroresModalidad = null;
						this.errorStep = false;
					})
					.catch((errors) => {
						// Manejar los errores de validación
						console.error('Errores de validación:', errors.errors);
						const formattedErrors = {};
						errors.inner.forEach((error) => {
							formattedErrors[error.path] = error.message;
						});

						this.erroresModalidad = formattedErrors;
						this.errorStep = true;
						errorValidacion = true;
					});

				if(!errorValidacion){
					let respaldos = null;
					if(parseInt(this.inscripcion.programaDescuentoId) == parseInt(this.formDatos.descuento.id)){
						respaldos = this.inscripcion.respaldo;
					}else{
						respaldos = this.formDatos.descuento?.datosVerificacion?.config?.documentos.map( item => ({
							nombre: item,
							archivo: null
						}));
					}

					const data = {
						diaPago: parseInt(this.formDatos.diaLimite),
						programaId: parseInt(this.programaId),
						numeroCuotas: this.programa.nivelProgramaId == 1 ? parseInt(this.formDatos.cuotas)+1 : parseInt(this.formDatos.cuotas),
						programaDescuentoId: parseInt(this.formDatos.descuento.id),
						
						correoFacturacion: this.formDatos.correoFacturacion,
						numDocumento: this.formDatos.numeroDocumento,
						razonSocial: this.formDatos.razonSocial,
						tipoDocumentoId: this.formDatos.tipoDocumento?.id,

						modalidadPago: this.formDatos.modalidadPago,
						
						// respaldo: this.respaldosDescuentos?.map(item => ({
						// 													nombre: item.nombre,
						// 													archivo: null, // item.archivo
						// 													verificado: item.verificado,
						// 												})),

						respaldo: respaldos,

						estudianteId: this.estudianteId,
						areaId: this.formDatos.areaEstudio.id,
						universidadId: this.formDatos.universidad.id,
						gradoAcademicoId: this.formDatos.gradoObtenido.id,
						destinatarioId: this.formDatos.tipoParticipante.destinatarioId,
						fechaDiploma: this.formDatos.fechaDiploma,
						fechaTitulo: this.formDatos.fechaTitulo,
					};
					
					try {
						const response = await InscripcionService.registrarModalidadFacturacion(data, this.inscripcionId);
						// console.log(response)
						if(response.data?.transaccion == true){
							this.errorStep = false;
							// this.inscripcion.respaldo = response.respaldo;
							await this.getUpdateInscripcion(this.inscripcionId);
						}else{
							this.errorStep = true;
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.data.mensaje, life: 3000});
						}
					} catch (error) {
						console.log(error);
						this.errorStep = true;
					}
				}					
			},

			async verDocumento(id, jsonData){
				try {
					const response = await ReporteService.getReporteBase64(id, jsonData);
					
					if(response.data?.transaccion){
						this.dataFile = response.data.datos;
						this.isBase64 = true;
						this.showVisor = true;						
					}else{
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Ocurrio un problema al obtener el documento', life: 3000});
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary:'Mensaje', detail:'Ocurrió un error al visualizar el documento. ' + error.data?.message, life: 3000});				
				}
			},

			verFormularioInscripcion(){
				let plantillaInscripcion = this.programa.programaPlantillas.filter(object => object.plantilla.plantillaTipoId == 4)[0];
				// console.log(plantillaInscripcion.plantillaId);
				const jsonData = jsonDataFormInscripcion(this.programa, this.formDatos);
				this.verDocumento(plantillaInscripcion.plantillaId, jsonData);
			},

			async verContrato(){
				let plantillaContrato = this.formDatos.descuento.programaDescuentoPlantillas.filter(object => (object.plantilla.plantillaTipoId == 3 && object.modalidad == this.formDatos.modalidadPago) )[0];
				// console.log(plantillaContrato.plantilla.id);
				if(plantillaContrato){
					this.inscripcion = await InscripcionService.getInscripcion(this.inscripcionId);

					const planPagos = {
						modalidad: this.formDatos.modalidadPago,
						descuento: this.formDatos.descuento.porcentaje,
						colegiaturaDescuento: this.planPagos.costoColegiaturaDescuento,
						numeroCuotas: parseInt(this.formDatos.cuotas),
						diaLimitePago: this.formDatos.diaLimite,
						montoCuota: this.planPagos.costoColegiaturaCuotas,
						montoPrimerPago: this.planPagos.costoPrimerPago,
						multaDefensaTutoria: this.planPagos.costoMulta,
					};

					const jsonData = jsonDataContrato(this.inscripcion, this.programa, this.formDatos.tipoParticipante, planPagos, plantillaContrato.plantilla.firma?.contenido);
					this.verDocumento(plantillaContrato.plantilla.id, jsonData);
				}else{
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'El contrato no cuenta con los parámetros de configuración correctos.', life: 3000});
				}
			},

			buscarUniversidades(event) {
				setTimeout(() => {
					if (!event.query.trim().length) {
						this.universidadesFiltrado = [...this.universidades];
					}
					else {
						this.universidadesFiltrado = this.universidades.filter((item) => {
							return item.nombre.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
			},

			buscarGrados(event) {
				setTimeout(() => {
					if (!event.query.trim().length) {
						this.gradosAcademicosFiltrado = [...this.gradosAcademicos];
					}
					else {
						this.gradosAcademicosFiltrado = this.gradosAcademicos.filter((item) => {
							return item.nombre.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
			},

			adjuntarRespaldo(respaldo){
				this.objetoRespaldo = respaldo;
				this.$refs.respaldo.click();
			},

			leerRespaldo(event){
				const file = event.target.files[0];
				if (file) {
					// console.log(file);
					this.getRespaldoBase64(file);
				}
			},

			getRespaldoBase64(file){
				const reader = new FileReader();
				reader.onload = () => {
					this.respaldoFile = reader.result;
					this.$nextTick( async () => {
						const data = {
							nombre: this.objetoRespaldo.nombre,
							archivo: this.respaldoFile.split(',')[1]
						};

						const response = await InscripcionService.actualizarRespaldo(data, this.inscripcionId);

						if(response.data?.transaccion == true){
							// await this.getRequisitosPrograma();
							await this.getUpdateInscripcion(this.inscripcionId);
							
							this.$toast.add({severity:'success', summary: 'Mensaje', detail: `Archivo actualizado`, life: 3000});
						}else{
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.data.mensaje, life: 3000});
						}
					});					
				};
				reader.readAsDataURL(file);
			},
			
			async verDocumentoRespaldo(respaldo){				
				try {
					const response = await ArchivoService.getArchivoBase64(respaldo.archivo);
				
					if(response.data?.transaccion){
						this.dataFile = response.data.datos;
						this.isBase64 = true;
						this.showVisor = true;						
					}else{
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Ocurrió un problema al obtener el archivo', life: 3000});
					}
				} catch (error) {
					console.log(error);
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Ocurrió un problema al obtener el archivo', life: 3000});
				}				
			},

			// existeRespaldo(objetoRespaldo){
			// 	const respaldo = this.respaldosDescuentos.find(objeto => objeto.nombre === objetoRespaldo);
			// 	return respaldo ? true : false;
			// },

			adjuntarRequisito(data, accion){
				this.$refs.requisito.click();
				this.objetoDocumento = data;
				this.accion = accion;
			},

			leerRequisito(event){
				const file = event.target.files[0];
				if (file) {
					// console.log(file);
					this.getRequisitoBase64(file);
				}
			},

			getRequisitoBase64(file){
				const reader = new FileReader();
				reader.onload = () => {
					this.requisitoFile = reader.result;					
					this.$nextTick( async () => {
						let response = null;
						if(this.accion == 'A'){
                            // Actualizar requisito
                            const data = {
                                tipo: "archivo",
                                dataServicio: null,
                                archivo: this.requisitoFile.split(',')[1],
                            };

                            response = await DocumentacionInscripcionService.actualizar(data, this.objetoDocumento.id);
                        }else{
                            const data = {
								inscripcionId: this.inscripcionId,
								documentoRequeridoProgramaId: this.objetoDocumento?.id,
								tipo: 'archivo',
								dataServicio: null,
								archivo: this.requisitoFile.split(',')[1],
							};
                            response = await DocumentacionInscripcionService.registrar(data);
                        }

						if(response.data?.transaccion == true){
                            // Actualizar lista
                            await this.getRequisitosPrograma();
                            
							this.$toast.add({severity:'success', summary: 'Mensaje', detail: `Archivo actualizado`, life: 3000});
                        }else{
							this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.data.mensaje, life: 3000});
						}					
					});					
				};
				reader.readAsDataURL(file);
			},

			adjuntarRequisitoGeneral(data, accion){
				this.$refs.requisitoGeneral.click();
				this.objetoDocumento = data;
				this.accion = accion;
			},

			leerRequisitoGeneral(event){
				const file = event.target.files[0];
				if (file) {
					// console.log(file);
					this.getRequisitoGeneralBase64(file);
				}
			},

			getRequisitoGeneralBase64(file){
                const reader = new FileReader();
                reader.onload = () => {
                    this.requisitoFile = reader.result;
                    this.$nextTick( async () => {
                        let response = null;

                        if(this.accion == 'A'){
                            // Actualizar requisito
                            const data = {
                                tipo: "archivo",
                                dataServicio: null,
                                archivo: this.requisitoFile.split(',')[1],
                            };

                            response = await PersonaDocumentoService.actualizar(data, this.objetoDocumento.id);
                        }else{
                            const data = {
                                nombre: this.objetoDocumento.nombre,
                                // personaId: this.inscripcion.estudiante.personaId,
								requisitoId: this.objetoDocumento?.destinatarioId ? null : this.objetoDocumento.id,
                                requisitoDestinatarioId: this.objetoDocumento?.destinatarioId ? this.objetoDocumento.id : null,
								tipo: 'archivo',
								dataServicio: null,
								archivo: this.requisitoFile.split(',')[1],
							};
                            response = await PersonaDocumentoService.registrar(data);
                        }
                        
                        if(response.data?.transaccion == true){
                            // Actualizar lista
							await this.getRequisitosGenerales();
                            this.$toast.add({severity:'success', summary: 'Mensaje', detail: `Archivo actualizado`, life: 3000});
                        }                      
                    });					
                };
                reader.readAsDataURL(file);
            },

			async verDocumentoRequisito(archivo){
				try {
					const response = await ArchivoService.getArchivoBase64(archivo);
					
					if(response.data?.transaccion){
						this.dataFile = response.data.datos;
						this.isBase64 = true;
						this.showVisor = true;						
					}else{
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Ocurrió un problema al obtener el archivo', life: 3000});
					}
				} catch (error) {
					console.log(error);
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Ocurrió un problema al obtener el archivo', life: 3000});
				}
			},

			adjuntarContrato(){
				this.$refs.contrato.click();
			},

			leerContrato(event){
				const file = event.target.files[0];
				if (file) {
					console.log(file);
					this.getContratoBase64(file);
				}
			},

			adjuntarComprobante(){
				this.$refs.comprobante.click();
			},

			leerComprobante(event){
				const file = event.target.files[0];
				if (file) {
					console.log(file);
					this.getComprobanteBase64(file);
				}
			},

			getComprobanteBase64(file){
				const reader = new FileReader();
				reader.onload = () => {
					// this.comprobanteFile = reader.result.split(',')[1];					
					this.comprobanteFile = reader.result;					
				};
				reader.readAsDataURL(file);
			},

			verDocumentoComprobante(){
				this.isBase64 = true;
				this.dataFile = this.comprobanteFile;
				this.showVisor = true;
			},

			async finalizarInscripcion(){
				let errorValidacion = false;
				await pagoSchema.validate(
					{
						...this.formPago,
						comprobante: this.comprobanteFile ? 'existe' : '',
					}
					, { abortEarly: false })
					.then(() => {
						errorValidacion = false;
						this.erroresPago = null;
						this.errorStep = false;
					})
					.catch((errors) => {
						// Manejar los errores de validación
						console.error('Errores de validación:', errors.errors);
						const formattedErrors = {};
						errors.inner.forEach((error) => {
							formattedErrors[error.path] = error.message;
						});

						this.erroresPago = formattedErrors;
						this.errorStep = true;
						errorValidacion = true;
					});
					
				if((!errorValidacion && this.formComprobante) || !this.formComprobante){
					if(this.configAnterior.contratoFile == this.contratoFile && this.existeCambiosConfigContrato()){
						this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Genere y adjunte el Contrato, porque se hicieron cambios en la modalidad de pago.', life: 5000});
					}else{
						if(this.configAnterior.contratoFile != this.contratoFile){
							if(this.contratoFile){
								const dataContrato = {
									archivo: this.contratoFile
								};
								
								try {
									const response = await InscripcionService.subirContrato(dataContrato, this.inscripcionId);
									console.log(response)
									if(response.data?.transaccion == true){
										console.log('Contrato registrado');
									}else{
										this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.data?.mensaje , life: 3000});
									}
								} catch (error) {
									console.log(error);
								}
							}else{
								this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Adjunte el contrato firmado', life: 3000});
							}
						}

						if(this.formComprobante && !this.pagoEdit){
							const dataComprobante = {
								inscripcionId: this.inscripcionId,
								nroComprobante: this.formPago.numeroComprobante,
								archivo: this.comprobanteFile.split(',')[1],
								tipo: this.formPago.tipo,
								nombreDepositante: this.formPago.nombreDepositante,
								monto: parseFloat(this.formPago.monto),
								fechaDeposito: this.formPago.fechaDeposito
							};

							let responsePago = null;
							if(this.pago?.id)
								responsePago = await PagoService.actualizarPrimerPago(dataComprobante, this.inscripcionId);
							else
								responsePago = await PagoService.registrarPago(dataComprobante);

							if(responsePago.data?.transaccion == true){
								setTimeout(() => {
									this.$router.push('/inscripciones/lista');
								}, 1200);
								this.$toast.add({severity:'success', summary: 'Mensaje', detail: 'Inscripción finalizada', life: 3000});
							}else{
								this.$toast.add({severity:'error', summary: 'Mensaje', detail: responsePago.data?.mensaje , life: 3000});
							}
						}else{
							setTimeout(() => {
								this.$router.push('/inscripciones/lista');
							}, 1200);
							this.$toast.add({severity:'success', summary: 'Mensaje', detail: 'Inscripción finalizada', life: 3000});
						}
						
					}
				}									
			},

			getContratoBase64(file){
				const reader = new FileReader();
				reader.onload = () => {
					this.contratoFile = reader.result;					
				};
				reader.readAsDataURL(file);
			},

			verDocumentoContrato(){
				this.isBase64 = true;
				this.dataFile = this.contratoFile;
				this.showVisor = true;
			},

			cerrarVisor(){
				this.showVisor = false;
			},

			callbackLoginGoogle(response){
				console.log(response);
			},
			
		}
	}
</script>
<style scoped>
	.step-content {
		display: none;
	}
	.active-content{
		display: block;
	}
	.p-steps .p-steps-item.p-highlight .p-steps-number{
		border-color: #18318B;
	}
	h2.title{
		font-weight: 710 !important;
	}
	h5{
		font-weight: 650;
	}
	.title-section{
		font-weight: 650;
	}
	.field > label{
		font-weight: 600;
	}

	#form-inscripcion{
		position: relative;
	}
	#form-inscripcion::before{
		content: ' ';
		height: 10px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 4px 4px 0 0;
		background-color: #e30613;
		background: rgb(227,6,19);
		background: -moz-linear-gradient(120deg, rgba(227,6,19,1) 5%, rgba(0,79,159,1) 90%);
		background: -webkit-linear-gradient(120deg, rgba(227,6,19,1) 5%, rgba(0,79,159,1) 90%);
		background: linear-gradient(120deg, rgba(227,6,19,1) 5%, rgba(0,79,159,1) 90%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e30613",endColorstr="#004f9f",GradientType=1);
	}

	.message-inscripcion p{
		font-size: 1.16rem;
	}

	.message-inscripcion .p-message-icon{
		width: 20px;
		height: 20px;
	}

</style>
